// return the user data from the session storage
export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }

  export const getRol = () => {
    const userStr = sessionStorage.getItem('appUserRoles');
    return sessionStorage.getItem('appUserRoles');
  }
  
  // return the token from the session storage
  export const getToken = () => {
    return sessionStorage.getItem('access_token');
  }
  export const getId = () => {
    return sessionStorage.getItem('id');
  }
  export const getEmail = () => {
    return sessionStorage.getItem('email');
  }
  export const getCompany = () => {
    return sessionStorage.getItem('company');
  }
  export const getDepartment = () => {
    return sessionStorage.getItem('department');
  }
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('username');
  }
  
  // set the token and user from the session storage
 /*  export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
  } */
  export const setUserSession = (token, rol,id, email,company,department) => {
    sessionStorage.setItem('access_token', token);
    sessionStorage.setItem('appUserRoles', rol);
    sessionStorage.setItem('id', id);
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('company', company);
    sessionStorage.setItem('department', department);
  }