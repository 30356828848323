import React, { useContext, useEffect, useLayoutEffect } from 'react'
import TopNavbar from './TopNavbar'
import Navbar from './Navbar'
import '../css/Layout.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { NewContext } from '../utils/ContextProvider'
import ModalAlert from './ModalAlert'

const Layout = ({children}) => {
  const location = useLocation();
  const {getToken, controlToken, flag, flagAlert} = useContext(NewContext)
  const session = sessionStorage.getItem('access_token');
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token && window.location.pathname !== '/') {
      // Redirigir a la página de login si no hay ningún token y la ruta no es la página de inicio
     /*  window.location.href = '/';  */// O la ruta de tu página de login
    }else if(getToken){
      return
      }else {
      session ? controlToken(session) : navigate('/')
    }
  }, [getToken]);

  if(location.pathname === '/') return children
  return (
        <div>
          {
            flagAlert ? <ModalAlert /> : null
          }
          <TopNavbar/>
          <div className='container-layout'>
                <Navbar />
                <div className={`${flag ? 'container-page' : 'container-page-close'}`}>
                    {children}
                </div>
          </div>
        </div>
  )
}

export default Layout
