import React, { useContext, useEffect, useState } from 'react';
import '../css/Registro.css';
import { getRegisterMonth, postPeriod } from '../utils/peticionesApi';
import TableRowRegister from '../forms/Registros/TableRowRegister';
import OptionComponenteRegister from '../forms/Registros/OptionComponentRegister';
import AgregarRegistro from '../forms/Registros/AgregarRegistro';
import Button from '../components/Button';
import { NewContext } from '../utils/ContextProvider';
import CustomPagination from '../components/CustomPagination';
import ImportarRegistroModal from '../forms/Registros/ImportarRegistroModal';

const Registro = () => {
  const { month, setMonth } = useContext(NewContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [registros, setRegistros] = useState([]);
  const [filterRegister, setFilterRegister] = useState([]);
  const [modalAgregarRegistro, setModalAgregarRegistro] = useState(false);
  const [modalAgregarPeriodo, setModalAgregarPeriodo] = useState(false);
  const [dataPeriodo, setDataPeriodo] = useState({
    name: '',
    end_time: '00:00',
    start_time: '00:00'
  });
  const [showModal, setShowModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [modalViewExport, setModalViewExport] = useState(false);

  const itemsPerPage = 20; // Mostrar 20 registros por página
  const months = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio", "julio",
    "agosto", "septiembre", "octubre", "noviembre", "diciembre",
  ];

  useEffect(() => {
    if (registros) {
      filtroRegistros();
    } else {
      listarRegistros();
    }
  }, [registros]);

  useEffect(() => {
    listarRegistros();
  }, [month]);

  const listarRegistros = async () => {
    const monthSelected = months[month];
    const year = new Date().getFullYear();
    try {
      const response = await getRegisterMonth(monthSelected, year);
      setRegistros(response?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  const filtroRegistros = () => {
    const groupedRegisters = {};

    registros?.forEach((item) => {
      const key = `${item.employeeId}_${item.objective?.id}_${item.place?.id}`;

      if (!groupedRegisters[key]) {
        groupedRegisters[key] = item;
      }
    });

    const filtered = Object.values(groupedRegisters);
    setFilterRegister(filtered);

    // Calcular el número total de páginas
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
  };

  const handleChangeFilter = (monthSelected) => {
    setMonth(monthSelected);
  };

  const agregarPuesto = async () => {
    const data = {
      name: dataPeriodo.name,
      start_time: `${dataPeriodo.start_time}:00`,
      end_time: `${dataPeriodo.end_time}:00`
    };
    try {
      await postPeriod(data);
      setModalAgregarPeriodo(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePeriodo = (e) => {
    setDataPeriodo({ ...dataPeriodo, [e.target.name]: e.target.value });
  };

  const paginatedData = filterRegister.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  if (modalAgregarRegistro) return <AgregarRegistro setModalAgregarRegistro={setModalAgregarRegistro} />;

  const closeImportModal = () => {
    setShowImportModal(false);
  };

  const openImportModal = () => {
    setShowImportModal(true);
  };
  
  return (
    <>
      <div className='contenedorRegistro'>
        <p className='titulo'>Registro</p>
        <OptionComponenteRegister
          agregarFunction={() => setModalAgregarRegistro(!modalAgregarRegistro)}
          setModalAgregarPeriodo={setModalAgregarPeriodo}
          modalAgregarPeriodo={modalAgregarPeriodo}
          handleChangeFilter={handleChangeFilter}
          upload={openImportModal}
          setModalViewExport={setModalViewExport}
          modalViewExport={modalViewExport}
        />
        {modalAgregarPeriodo && (
          <div className='modalAgregarPeriodo'>
            <p className='tituloAgregarPeriodo'>Agregar Periodo</p>
            <div>
              <p>Nombre del periodo</p>
              <input type='text' className='inputRegistro' value={dataPeriodo.name} name='name' onChange={handleChangePeriodo} placeholder='Ingrese el nombre' />
            </div>
            <div className='row'>
              <div>
                <p>Inicio</p>
                <input type='time' className='inputRegistro' value={dataPeriodo.start_time} name='start_time' onChange={handleChangePeriodo} />
              </div>
              <div>
                <p>Término</p>
                <input type='time' className='inputRegistro' name='end_time' value={dataPeriodo.end_time} onChange={handleChangePeriodo} />
              </div>
            </div>
            <div className='contenedorButtonAgregarPuesto'>
              <Button tipe='violeta' msj='Agregar Puesto' onClick={agregarPuesto} />
            </div>
          </div>
        )}

        <div>
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>DNI</th>
                <th>Objetivo</th>
                <th>Puesto</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length === 0 ? (
                <tr><td colSpan="5">No hay registros para mostrar</td></tr>
              ) : (
                paginatedData.map((item, index) => (
                  <TableRowRegister key={index} EId={item.employeeId} objetivo={item.objective.name} puesto={item.place.name} />
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ImportarRegistroModal
          showModal={showImportModal}
          closeModal={closeImportModal}
        />
      <div className="d-flex justify-content-center mt-4">
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

export default Registro;
