import React, { Fragment, useState } from "react";
import Button from "../../components/Button";
import "../../css/UpdateRecord.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const UpdateRecordModal = ({ record, onSave, onCancel }) => {
  const [horarioCortado, setHorarioCortado] = useState(false)
  const [newHorario, setNewHorario] = useState([])
  const [deletePeriodo, setDeletePeriodo] = useState(false)
  const [registros, setRegistros] = useState(record.dates ? [...record.records, ...record.dates[Object.keys(record.dates)[record.index + 1]]] : record.records)

  const handleTimeChange = (index, newTime) => {
    const updatedRecords = [...registros];
    updatedRecords[index].time = newTime;
    setRegistros(updatedRecords)
  };

  const handleNewHorario =(field, horario)=>{
    const data = [...newHorario, {[field]: horario}]
    setNewHorario(data)
  }

  const deleteHorarioCortado =()=>{
    setRegistros(registros.filter(registro => registro.shiftId === 1))
    registros[0].shiftId = 4
    registros[1].shiftId = 4
    setDeletePeriodo(true)
  }

  return (
    <div className="contenedorUpdateRecord">
      <p>
        Fecha: <span className="textoFecha">{record.date}</span>
      </p>
      <div className="contenedorHorariosUpdate">
        {registros?.map((registro, index) => {
          return (
            <Fragment key={index}>
              <input type="time" value={registro.time} onChange={(e) => handleTimeChange(index, e.target.value)}/>
              {
                registro.type === 'I' && <FontAwesomeIcon icon={faArrowRightLong} />
              }
              {
                registro.type === 'E' && registro.shiftId === 1 ? <FontAwesomeIcon icon={faMinus} onClick={()=>deleteHorarioCortado()} className="iconEliminarHorarioCortado"/> : registro.shiftId === 4 && registro.type === 'E' && horarioCortado === false ? <FontAwesomeIcon icon={faPlus} className="iconHorarioCortadoUpdate" onClick={()=> setHorarioCortado(true)}/> : null
              }
              
            </Fragment>
          ) 
        })}
         {
                horarioCortado &&
                <Fragment>
                  <FontAwesomeIcon icon={faMinus} onClick={()=> setHorarioCortado(false)} className="iconEliminarHorarioCortado"/>
                  <input type="time" value={newHorario ? newHorario.newStartHorario : '00:00'} onChange={(e) => handleNewHorario('newStartHorario', e.target.value)}/>
                  <FontAwesomeIcon icon={faArrowRightLong} />
                  <input type="time" value={newHorario ? newHorario.newEndHorario : '00:00'} onChange={(e) => handleNewHorario('newEndHorario', e.target.value)}/>
                </Fragment>
              }
      </div>
      <div className="contenedorHorariosUpdate">
        <Button msj='Modificar' onClick={()=> onSave(registros, newHorario, deletePeriodo)}/>
        <Button msj="cancelar" tipe='btnGris' onClick={() => onCancel()} />
      </div>
    </div>
  );
};

export default UpdateRecordModal;
