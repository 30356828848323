import React from 'react'
import '../css/OptionComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faXmark, faUpload, faPlus } from '@fortawesome/free-solid-svg-icons'

const OptionComponentObjetivos = ({value, onChange, modalView,menuVisible, setMenuVisible}) => {
    return (
        <div className='contenedorOptions'>
          <input className='inputComponent' type='text' placeholder='Buscar por nombre, apellido o DNI' value={value} onChange={onChange}/>
          <div>
            <button className='button-icons-options'  onClick={()=> {setMenuVisible(!menuVisible)}}>
              {
                !modalView ?
                  <FontAwesomeIcon icon={faPlus} className='icon-options'/>
                :
                  <FontAwesomeIcon icon={faXmark} className='icon-options-close'/>
              }
            </button>
          </div>
        </div>
  )
}

export default OptionComponentObjetivos
