import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { getPersona } from '../utils/peticionesApi'

const SelectEmpleados = ({ setUuid, defaultValue }) => {
    const [empleados, setEmpleados] = useState([])
    const [selectedOption, setSelectedOption] = useState(null)

    useEffect(() => {
        const findEmpleados = async () => {
            const res = await getPersona()
            if (res && res.data.content) {
                const newEmpleados = res.data.content.map((empleado) => ({
                    value: `${empleado.id}`,
                    label: `${empleado.person.name} ${empleado.person.lastname} dni:${empleado.person.national_id}`
                }))
                setEmpleados(newEmpleados)

                if (defaultValue) {
                    const defaultOption = newEmpleados.find(option => option.value === defaultValue)
                    setSelectedOption(defaultOption)
                }
            }
        }
        findEmpleados()
    }, [defaultValue])

    const handleChange = (select) => {
        setUuid(select.value)
        setSelectedOption(select)
    }

    return (
        <Select 
            options={empleados} 
            onChange={handleChange} 
            placeholder='Buscar empleado' 
            value={selectedOption} 
        />
    )
}

export default SelectEmpleados