import React, { useState, useContext } from "react";
import "../css/TopNavbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faHeadset } from "@fortawesome/free-solid-svg-icons";
import { NewContext } from "../utils/ContextProvider";
import ModalConTabla from "../components/ModalConTabla.jsx";

function TopNavbar() {
  const {
    getCompany,
    getRol,
    setFlag,
    flag,
    presente,
    contador,
    selectedEmployee,
  } = useContext(NewContext);

  const [modalAbierto, setModalAbierto] = useState(false);
  const ulrPicturePersonal = "https://www.softnik.cloud/awsProfile/getProfile";

  const handleCampanaClick = async () => {
    setModalAbierto(true);
  };

  const handleSupportClick = () => {
    const phoneNumber = "+5492234550356";
    const message = "Hola, estoy utilizando la aplicación para control de presentismo y asistencia y necesito soporte. ¿Podrían ayudarme, por favor?";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="navbar-inner">
      <div className="img-mobile">
        <img
          src={require("../image/Logo-softnik-positivo.png")}
          alt="Logo-softnik"
        />
      </div>

      <div className="container-button-user">
        {getRol !== "ROLE_USER" && (
          <div>
            <FontAwesomeIcon
              icon={faBell}
              style={{
                color: "#2A9AB6",
                cursor: "pointer",
                fontSize: "22px",
                marginLeft: "20%",
              }}
              onClick={handleCampanaClick}
            />
            <div>
              {contador > 0 && presente && Object.keys(presente).length > 0 && (
                <span className="alert-contador" id="contador">
                  {contador}
                </span>
              )}
            </div>
            <ModalConTabla
              show={modalAbierto}
              handleClose={() => setModalAbierto(false)}
              data={presente ? presente.content : []}
            />
          </div>
        )}

        <div>
          <FontAwesomeIcon
            icon={faHeadset}
            style={{
              color: "#2A9AB6",
              cursor: "pointer",
              fontSize: "22px",
              marginLeft: "20%",
            }}
            onClick={handleSupportClick}
          />
        </div>
        <div>
          <FontAwesomeIcon
            icon={faBars}
            className="icon-menu-topbar"
            onClick={() => setFlag(!flag)}
          />
          {selectedEmployee && (
            <img
              src={`${ulrPicturePersonal}?uuid=${selectedEmployee.id}&company=${getCompany}`}
              className="img-company"
              alt={`${getCompany}`}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TopNavbar;
