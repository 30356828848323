import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import { getAllDepartment } from '../utils/peticionesApi';
import { NewContext } from "../utils/ContextProvider";

const SelectDepartamentos = ({ setIdDepartamento, defaultValue }) => {
    const [departamentos, setDepartamentos] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const { getCompany } = useContext(NewContext);

    useEffect(() => {
        const findDepartamentos = async () => {
            try {
                const res = await getAllDepartment(getCompany);

                if (res && res.data && Array.isArray(res.data.data)) {
                    const newDepartamentos = res.data.data.map((departamento) => ({
                        value: `${departamento.id}`,
                        label: `${departamento.dept_name}`
                    }));

                    setDepartamentos(newDepartamentos);

                    if (defaultValue) {
                        const defaultOption = newDepartamentos.find(option => option.value === defaultValue);
                        setSelectedOption(defaultOption);
                    }
                } else {
                    console.error('La estructura de datos del backend no es la esperada:', res);
                }
            } catch (error) {
                console.error('Error al obtener departamentos:', error);
            }
        };
        findDepartamentos();
    }, [defaultValue, getCompany]);

    const handleChange = (selected) => {
        setIdDepartamento(selected?.value || null);
        setSelectedOption(selected);
    };

    return (
        <Select
            options={departamentos}
            onChange={handleChange}
            placeholder='Buscar por departamento'
            value={selectedOption}
        />
    );
};

export default SelectDepartamentos;
