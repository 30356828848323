import React, { useRef } from 'react';
import '../../css/OptionComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faXmark, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';

const OptionComponentDocuments = ({ value, onChange, setModalView, modalView, setModalViewExport, modalViewExport, setModalCreateFolder, handleFileChange }) => {
    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="contenedorOptions">
            <div className="contenedorInputOptionRegisters">
                <input
                    className="inputComponentRegisters"
                    type="text"
                    placeholder="Buscar por carpeta y/o documento"
                    value={value}
                    onChange={onChange}
                />
            </div>
            <div>
                <button
                    className="button-icons-options"
                    onClick={() => setModalCreateFolder(true)}
                >
                    {!modalView ? (
                        <FontAwesomeIcon icon={faPlus} className="icon-options" />
                    ) : (
                        <FontAwesomeIcon icon={faXmark} className="icon-options-close" />
                    )}
                </button>
                <button
                    className="button-icons-options"
                    onClick={() => {
                        setModalViewExport(!modalViewExport);
                        setModalView(false);
                    }}
                >
                    {!modalViewExport ? (
                        <FontAwesomeIcon icon={faDownload} className="icon-options" />
                    ) : (
                        <FontAwesomeIcon icon={faXmark} className="icon-options-close" />
                    )}
                </button>
                <button className="button-icons-options" onClick={handleUploadClick}>
                    <FontAwesomeIcon icon={faUpload} className="icon-options" />
                </button>
                {/* Input oculto para cargar archivos */}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </div>
        </div>
    );
};

export default OptionComponentDocuments;
