import React from 'react'
import '../css/OptionComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faDownload, faXmark } from '@fortawesome/free-solid-svg-icons'

const OptionComponentReporteEventos = ({ type, placeHolder, value, onChange, setModalView, modalView, setModalViewExport, modalViewExport, }) => {

    return (
        <div className='contenedorOptions'>
            <input className='inputComponent' type={type} placeholder={placeHolder} value={value} onChange={onChange} />
            <div>
                <button className='button-icons-options' onClick={() => { setModalView(!modalView); setModalViewExport(false) }}>
                    {
                        !modalView ?
                            <FontAwesomeIcon icon={faCalendar} className='icon-options' />
                            :
                            <FontAwesomeIcon icon={faXmark} className='icon-options-close' />
                    }
                </button>
                <button className='button-icons-options' onClick={() => { setModalViewExport(!modalViewExport); setModalView(false) }}>
                    {
                        !modalViewExport ?
                            <FontAwesomeIcon icon={faDownload} className='icon-options' />
                            :
                            <FontAwesomeIcon icon={faXmark} className='icon-options-close' />
                    }

                </button>
            </div>
        </div>
    )
}

export default OptionComponentReporteEventos;
