import React, { useContext, useEffect, useState, useMemo } from "react";
import "react-calendar/dist/Calendar.css";
import "../css/Solicitudes.css";
import CustomPagination from "../components/CustomPagination";
import { NewContext } from "../utils/ContextProvider";
import { getStadiscticArrival } from "../utils/peticionesApi";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons';

const Tardanzas = () => {
  const { getToken, getId } = useContext(NewContext);
  const [tardanzas, setTardanzas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (getToken && getId) {
      listarTardanzas();
    }
  }, [getToken, getId]);

  const listarTardanzas = async () => {
    try {
      const response = await getStadiscticArrival();
      if (response && response.data) {
        setTardanzas(response.data);
      } else {
        console.error("Error: La respuesta no contiene datos.");
      }
    } catch (error) {
      if (error.response) {
        console.error(`Error en la solicitud: ${error.response.status} - ${error.response.statusText}`);
      } else {
        console.error("Error al hacer la solicitud:", error.message);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

  const formatRegistry = (registryArray) => {
    if (Array.isArray(registryArray) && registryArray.length >= 6) {
      const hours = registryArray[3].toString().padStart(2, '0');
      const minutes = registryArray[4].toString().padStart(2, '0');
      const seconds = registryArray[5].toString().padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }
    return "Hora inválida";
  };

  const filteredTardanzas = useMemo(() => {
    return tardanzas.filter((t) =>
      `${t.name} ${t.lastname}`.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, tardanzas]);

  const tardanzasPorPagina = 15;
  const paginatedTardanzas = useMemo(() => {
    const start = currentPage * tardanzasPorPagina;
    const end = start + tardanzasPorPagina;
    return filteredTardanzas.slice(start, end);
  }, [currentPage, filteredTardanzas]);

  const TableRow = ({ tardanza }) => {
    return (
      <tr>
        <td>{tardanza.name} {tardanza.lastname}</td>
        <td>{formatDate(tardanza.date)}</td>
        <td>{tardanza.time}</td>
        <td>{formatRegistry(tardanza.registry)}</td>
        <td>{tardanza.objective.name}</td>
        <td>{tardanza.place.name}</td>
      </tr>
    );
  };

  return (
    <div className="contenedor-solicitudes">
      <button
        onClick={() => window.history.back()}
        className="buttonVolverObj"
      >
        <FontAwesomeIcon icon={faCircleLeft} />
        Volver
      </button>
      <p className="titulo">Tardanzas</p>
      <div className="data-table">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Fecha</th>
              <th>Hora Ingreso</th>
              <th>Registro</th>
              <th>Objetivo</th>
              <th>Puesto</th>
            </tr>
          </thead>
          <tbody>
            {paginatedTardanzas.length === 0 ? (
              <tr>
                <td colSpan="7">No se encontraron resultados.</td>
              </tr>
            ) : (
              paginatedTardanzas.map((tardanza, index) => (
                <TableRow key={index} tardanza={tardanza} />
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <CustomPagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredTardanzas.length / tardanzasPorPagina)}
          setCurrentPage={setCurrentPage}
          hasNextPage={currentPage < Math.ceil(filteredTardanzas.length / tardanzasPorPagina) - 1}
        />
      </div>
    </div>
  );
};

export default Tardanzas;