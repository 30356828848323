import React, { useState, useContext } from 'react';
import "../../css/ImportarRegistroModal.css";
import { getImportAsistent } from "../../utils/peticionesApi";
import { NewContext } from "../../utils/ContextProvider";

const ImportarRegistroModal = ({ showModal, closeModal }) => {
    const [file, setFile] = useState(null);
    const { getAlert } = useContext(NewContext);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmit = () => {
        if (!file) {
            getAlert({ msj: "Selecciona un archivo antes de importar.", tipe: 'warning' });
            return;
        }

        getImportAsistent(file)
            .then(response => {
                if (response.status === 200) {
                    getAlert({ msj: "Archivo importado exitosamente.", tipe: 'succes' });
                    closeModal();
                } else {
                    getAlert({ msj: "Error al importar el archivo. Intenta nuevamente.", tipe: 'error' });
                }
            })
            .catch(error => {
                getAlert({ msj: "Hubo un error al importar el archivo. Por favor, intenta de nuevo.", tipe: 'error' });
            });
    };

    return (
        showModal && (
            <div className="modal-overlay">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Importar Registros Asistencia</h2>
                        <button onClick={closeModal} className="close-button">X</button>
                    </div>
                    <div className="modal-body">
                        <label htmlFor="file-upload" className="file-upload">
                            Archivo de importación:
                            <input type="file" id="file-upload" onChange={handleFileChange} />
                        </label>
                        <p className="template-download">
                            <a href="/templates/plantilla-Asistencia.xlsx" download>Descargar plantilla</a>
                        </p>
                        <div className="table-preview">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Apellido</th>
                                        <th>DNI</th>
                                        <th>Turno</th>
                                        <th>Ingreso</th>
                                        <th>Egreso</th>
                                        <th>Objetivo</th>
                                        <th>Puesto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>German</td>
                                        <td>Oyarzo</td>
                                        <td>10325699</td>
                                        <td>Jornada</td>
                                        <td>07:00</td>
                                        <td>17:00</td>
                                        <td>Trenes</td>
                                        <td>Hall Central</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="description">
                            <p>1. El encabezado en el archivo de plantilla es requerido</p>
                            <p>2. El Dni del Empleado, Nombres, Objetivo, Puestos son campos requeridos</p>
                            <p>3. Todos los valores de la columna deben ser en formato de TEXTO</p>
                            <p>4. El nombre del Objetivo y Puesto tiene que ser exacto</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={handleSubmit} className="confirm-button">Confirmar</button>
                        <button onClick={closeModal} className="cancel-button">Cancelar</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default ImportarRegistroModal;
