import React from 'react';
import ReactDOM from 'react-dom';
import '../src/css/icons.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { axiosInterceptor } from './interceptors/axios.interceptor';

axiosInterceptor()

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

reportWebVitals();