import React, { useContext } from 'react'
import { useNavigate } from "react-router";
import { removeUserSession } from '../utils/common';
import '../css/Navbar.css'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faArrowRightFromBracket, faHouse, faClock, faListCheck, faUser, faBuilding, faUsers, faEnvelopeOpenText, faClipboard, faPersonWalkingArrowRight, faSatelliteDish, faFingerprint, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { NewContext } from '../utils/ContextProvider';
import { NavLink } from 'react-router-dom';

function Navbar() {
  const { flag, setFlag, getToken, getRol, getId, setStates, getDepartment } = useContext(NewContext)
  const navigate = useNavigate();
  const url = 'https://www.softnik.live/upload';
  //const id = "6eb9dcfa-e2e4-4179-b815-fb543e5de4cc";
  const Swal = require('sweetalert2')

  const importarExcel = async (file) => {

    try {
      const formData = new FormData();
      formData.append('file', file);

      const requestOptions = {
        method: 'POST',
        data: formData,
        url: url,
        headers: {
          Authorization: `Bearer ${getToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(requestOptions);
      if (response.status === 200) {
        if (response.data.success) {


          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Excel agregado con éxito.',
            showConfirmButton: false,
            timer: 1500
          })
        }

      } else {
        console.error('Hubo un problema al importar el excel.');
      }
    } catch (error) {
      console.error('Error al importar el excel:', error.response.data.message);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
        footer: 'Formato válido: .xlsx'
      })
      console.log(error)
      // Manejar el error y establecer el estado de error

    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    event.target.value = '';
    importarExcel(file)

  };
  const logOut = () => {
    setStates()
    removeUserSession();

  };

  const handleLogout = () => {
    logOut();
    navigate("/");
  };
  return (
    <div className={`sidebar ${!flag && 'open'}`}>
      <FontAwesomeIcon className={`${!flag && 'inactive'} icon-flag close`} icon={faChevronLeft} onClick={() => setFlag(!flag)} />
      <FontAwesomeIcon className={`${flag && 'inactive'} icon-flag open`} icon={faChevronRight} onClick={() => setFlag(!flag)} />
      <div className='contenedor-navbar-items'>
        <div className={`${flag ? 'list-menu' : 'list-menu-close'}`}>
          <NavLink
            to="/home/"
            className='item-navbar'
            onClick={() => window.innerWidth < 1081 ? setFlag(!flag) : null}
          >
            <FontAwesomeIcon icon={faHouse} className='icon-flag' />
            <p className={`${!flag && 'inactive'}`}>Inicio</p>
          </NavLink>
          {(getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR') && (
            <NavLink
              to="/registro/"
              className='item-navbar'
              onClick={() => window.innerWidth < 1081 ? setFlag(!flag) : null}
            >
              <FontAwesomeIcon icon={faClipboard} className='icon-flag' />
              <p className={`${!flag && 'inactive'}`}>Registro</p>
            </NavLink>
          )}
          {(getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR') && (
            <NavLink
              to="/visitantes"
              className='item-navbar'
              onClick={() => window.innerWidth < 1081 ? setFlag(!flag) : null}
            >
              <FontAwesomeIcon icon={faPersonWalkingArrowRight} className='icon-flag' />
              <p className={`${!flag && 'inactive'}`}>Visitantes</p>
            </NavLink>
          )}
          <NavLink
            to={`/jornada/${getId}`}
            className='item-navbar'
            onClick={() => window.innerWidth < 1081 ? setFlag(!flag) : null}
          >
            <FontAwesomeIcon icon={faClock} className='icon-flag' />
            <p className={`${!flag && 'inactive'}`}>Jornada</p>
          </NavLink>
          {(getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR' || getRol === 'ROLE_OPERADOR') && (
            <NavLink
              to="/asistencias/"
              className='item-navbar'
              onClick={() => window.innerWidth < 1081 ? setFlag(!flag) : null}
            >
              <FontAwesomeIcon icon={faListCheck} className='icon-flag' />
              <p className={`${!flag && 'inactive'}`}>Asistencias</p>
            </NavLink>
          )}
          {(getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR' || getRol === 'ROLE_OPERADOR') && Array.isArray(getDepartment) && getDepartment.length > 0 && (
            <NavLink
              to="/reloj/"
              className="item-navbar"
              onClick={() => (window.innerWidth < 1081 ? setFlag(!flag) : null)}
            >
              <FontAwesomeIcon icon={faFingerprint} className="icon-flag" />
              <p className={`${!flag && 'inactive'}`}>Reloj</p>
            </NavLink>
          )}
          {(getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR' || getRol === 'ROLE_OPERADOR') && (
            <NavLink
              to="/empleados/"
              className='item-navbar'
              onClick={() => window.innerWidth < 1081 ? setFlag(!flag) : null}
            >
              <FontAwesomeIcon icon={faUsers} className='icon-flag' />
              <p className={`${!flag && 'inactive'}`}>Empleados</p>
            </NavLink>
          )}
          {(getRol === 'ROLE_ADMIN' || getRol === 'ROLE_RRHH' || getRol === 'ROLE_SUPERVISOR' || getRol === 'ROLE_OPERADOR') && (
            <NavLink
              to="/objetivos/"
              className='item-navbar'
              onClick={() => window.innerWidth < 1081 ? setFlag(!flag) : null}
            >
              <FontAwesomeIcon icon={faBuilding} className='icon-flag' />
              <p className={`${!flag && 'inactive'}`}>Empresas</p>
            </NavLink>
          )}
          <NavLink
            to={`/solicitudes`}
            className='item-navbar'
            onClick={() => window.innerWidth < 1081 ? setFlag(!flag) : null}
          >
            <FontAwesomeIcon icon={faEnvelopeOpenText} className='icon-flag' />
            <p className={`${!flag && 'inactive'}`}>Solicitudes</p>
          </NavLink>

          {/*
          <NavLink
            to="/documentos/"
            className='item-navbar'
            onClick={() => window.innerWidth < 1081 ? setFlag(!flag) : null}
          >
            <FontAwesomeIcon icon={faFolderOpen} className='icon-flag' />
            <p className={`${!flag && 'inactive'}`}>Documentos</p>
          </NavLink>
          */}
          <NavLink
            to={`/miperfil`}
            className='item-navbar'
            onClick={() => window.innerWidth < 1081 ? setFlag(!flag) : null}
          >
            <FontAwesomeIcon icon={faUser} className='icon-flag' />
            <p className={`${!flag && 'inactive'}`}>Mi perfil</p>
          </NavLink>
        </div>
        <div className="sidebar-footer">
          <FontAwesomeIcon className={`icon-flag-session open`} onClick={handleLogout} href="/" icon={faArrowRightFromBracket} />
          <button className={`logout-button ${!flag && 'inactive'}`} onClick={handleLogout} href="/">Cerrar Sesión</button>
        </div>
      </div>
    </div>
  )
}

export default Navbar;