import React, { useContext, useEffect, useState } from 'react'
import { getPersonaId } from '../../utils/peticionesApi'
import { useNavigate } from 'react-router-dom'
import { NewContext } from '../../utils/ContextProvider'

const TableRowRegister = ({EId, objetivo, puesto}) => {
  const {setEmpleadoNameRegister} = useContext(NewContext)
    const [empleado, setEmpleado] = useState()
    const navigation = useNavigate()

    useEffect(()=>{
        listarDatosPersona()
    }, [])

    const listarDatosPersona =async ()=>{
        const response = await getPersonaId(EId)
        setEmpleado(response.data.person)
    }

    const handleNavigationRegistro =()=>{
      setEmpleadoNameRegister(`${empleado.name} ${empleado.lastname}`)
      navigation(`/registro/${EId}/${empleado.name} ${empleado.lastname}`)
    }
   
  return (
    <tr className="pointer" onClick={handleNavigationRegistro}>
        <td>{empleado?.name}</td>
        <td>{empleado?.lastname}</td>
        <td>{empleado?.national_id}</td>
        <td>{objetivo}</td>
        <td>{puesto}</td>
    </tr>
  )
}

export default TableRowRegister
