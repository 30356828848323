import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import '../css/Camara.css';
import Button from './Button';

const CameraComponent = ({ onPhotoCapture }) => {
  const webcamRef = useRef(null);

  const takePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    onPhotoCapture(imageSrc);
  };

  return (
    <div className="webcam-container">
      <Webcam
        className='video'
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        screenshotQuality={0.92}
        forceScreenshotSourceSize={true}
        videoConstraints={{ facingMode: 'user' }}
        width={400}
        height={400}
        
      />
      <Button msj='Tomar Foto' onClick={takePhoto}/>
    </div>
  );
};

export default CameraComponent;
