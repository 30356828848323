import { removeUserSession } from "../utils/common";
import imagenLogo from "../image/Logo-softnik-positivo-color.png";
import "../css/Login.css";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NewContext } from "../utils/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { postUserLogin } from "../utils/peticionesApi";
import Swal from "sweetalert2";
import Button from '../components/Button'

const LoginPage = () => {
  const { controlToken, setStates } = useContext(NewContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authLoading, setAuthLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setStates()
    setError(null);
    setLoading(true);
    try {
      const response = await postUserLogin({ password: password, username: username })
      if (response?.status === 200) {
        sessionStorage.setItem("username", username);
        sessionStorage.setItem('access_token', response.data);
        controlToken(response.data)
        setLoading(false);
        // Aquí también corregí cómo pasas el nombre de usuario
        setAuthLoading(false);
        navigate("/home/");
      }
    } catch (error) {
      setLoading(false)
      removeUserSession()
      setAuthLoading(false)
      setError("Algo salió mal. Intentelo nuevamente.")
    }
  };

  /*  const enviarMail = (email) => {
     const config = {
        headers: {
         Authorization: `Bearer ${session}` // Asumiendo que session es el token de autorización
       }, 
       params: {
         email: email.trim(),
       },
     };
 
     axios
       .post(url + "/sendEmail", null, config)
       .then((response) => {
         setEmail(response.data);
         setErrorEmail(
           "Email enviado. Por favor, revise su correo electrónico."
         );
       })
       .catch((error) => {
         console.log(error);
         if (error.response.status === 400 || error.response.status === 401)
           setErrorEmail(
             "No existe un usuario con el email ingresado",
             error.response.data
           );
         else {
         }
       });
   }; */

  const mostrarSweetAlert = () => {
    Swal.fire({
      title: "Ingrese su email",
      input: "email",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Correo electrónico",
      },
      showCancelButton: true,
      confirmButtonText: "Enviar Mail",
      confirmButtonColor: "#0d6efd",
      icon: "info",
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        // Lógica para enviar el correo electrónico o cualquier otra validación que desees realizar
        /* enviarMail(email); */
      },
      //allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      // Puedes agregar lógica adicional después de que se confirma el SweetAlert
      if (result.isConfirmed) {
        // Por ejemplo, mostrar otro SweetAlert
        Swal.fire("Correo enviado con éxito", "", "success");
      }
    });
  };
  function myFunction() {
    var x = document.getElementById("showPassword");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }


  return (
    <div className="login">
      <div className="contenedor-login-img">
        <p className="text-login poppins-xl">¡Únete a la transformación digital de la gestión de asistencia con SoftNik!</p>
        <div className="rectangulo-login"></div>
        <img src='imgLogin.gif' alt="Presentacion imagen" className="imagen-login" />
      </div>
      <div className="contenedor-form-login">
        {
          <div
            className="login-container"
          >
            <img src={imagenLogo} alt="Logo de la empresa" className="logo-empresa" />
            <div className="contenedor-titulo">
              <p className="title-login dm-sans"> ¡Hola! </p>
              <p className="description-login dm-sans"> Te damos la bienvenida. </p>
            </div>
            <div className="">
              <div className="input-group mb-3">
                <input
                  className="input-login"
                  placeholder="Usuario"
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  autoComplete="new-password"
                />
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faUser} className="icon-input-login" />
                </span>
              </div>
              <div className="input-group mb-3">
                <input
                  className="input-login"
                  placeholder="Contraseña"
                  id="showPassword"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                />
                <span className="input-group-text" onClick={myFunction}>
                  <FontAwesomeIcon icon={faLock} className="icon-input-login" />
                </span>
              </div>

              <div className="">
              </div>

              {error && (
                <>
                  <small style={{ color: "black" }}>{error}</small>
                  <br />
                </>
              )}
              <br />
              <Button
                msj={loading ? "Cargando.." : "INGRESAR"}
                onClick={!loading ? handleLogin : null}
              />
              <button onClick={mostrarSweetAlert} className="recuperarContraseña"> Olvidé mi contraseña </button>
            </div>
            <div className="copyright">
              <p>Copyright © 2023 SoftNik. Todos los derechos reservados.</p>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default LoginPage;
