import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import '../css/CalenderEmployee.css';
import { NewContext } from "../utils/ContextProvider";

const CalendarioEmployee = () => {
  const { getToken, getId } = useContext(NewContext)
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [employeeForSelectedDate, setEmployeeForSelectedDate] = useState(null);
  const [allEmployee, setAllEmployee] = useState(null);

  const session = getToken;
  const url = 'https://www.softnik.live/registers/';
  const employeesPerPage = 10;
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);


  function obtenerNombreMesEnEspanol(fecha) {
    const mesesEnEspanol = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];

    const mes = fecha.getMonth();
    return mesesEnEspanol[mes];
  }

  useEffect(() => {
    if(getId){

      const fetchEmployeeData = async () => {
        try {
          const response = await axios.get(url + getId + '/' + obtenerNombreMesEnEspanol(selectedMonth), {
            headers: {
              Authorization: `Bearer ${session}`
            },
            params: {
              month: obtenerNombreMesEnEspanol(selectedMonth),
              page: currentPage,
              size: 100,
              uuid: getId
            }
          });
  
          const employeeData = response.data.content;
          setAllEmployee(employeeData)
          const dateArray = employeeData.map(employee => {
            const [day, month, year] = employee.date.split('/').map(Number);
            return new Date(year, month - 1, day);
          });
  
          setSelectedEmployee(employeeData);
        } catch (error) {
          console.log(error);
        }
      };
  
      fetchEmployeeData();
    }
  }, [currentPage, getId]);

  const tileContent = ({ date, view }) => {
    const isHighlighted = selectedEmployee.some(employee => isSameDate(employee.date, date))
    return isHighlighted ? (
      <div className="green-circle">
        { }
      </div>
    ) : null;
  };

  const isSameDate = (dateStr, date) => {
    const [day, month, year] = dateStr.split('/').map(Number);
    return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year;
  };

  const handleDateChange = async (date) => {
    setSelectedDate(date);

    const matchingEmployees = selectedEmployee.reduce((result, employee) => {
      const [day, month, year] = employee.date.split('/').map(Number);
      const employeeDate = new Date(year, month - 1, day).getTime();

      if (employeeDate === date.getTime()) {
        const existingEmployee = result.find(emp => emp.id === employee.id);
        if (existingEmployee) {
          if (employee.type === 'I') {
            existingEmployee.jornadaInicial = employee.time;
          } else if (employee.type === 'E') {
            existingEmployee.jornadaFinal = employee.time;
          }
        } else {
          result.push({
            id: employee.id,
            date: employee.date,
            objective: employee.objective.name,
            place: employee.place.name,
            jornadaInicial: employee.type === 'I' ? employee.time : null,
            jornadaFinal: employee.type === 'E' ? employee.time : null,
          });
        }
      }

      return result;
    }, []);

    setEmployeeForSelectedDate(matchingEmployees);
  };

  const isDateDisabled = ({ date }) => {
    return !selectedEmployee || !selectedEmployee.some(selectedEmp => isSameDate(selectedEmp.date, date));
  };

  const [showModal, setShowModal] = useState(false); // Estado para controlar la visibilidad del modal


  const handleModalCalenderEmpleadoClick = (employeeData) => {
    setEmployeeForSelectedDate(employeeData); // Actualiza los datos del empleado para el día seleccionado
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false); // Cambia el estado para cerrar el modal
  };


  return (
    <div className="">
          {/* <div className="calenderContainer">
              <h2 className="title-calendar"><strong>Calendario</strong></h2>
              <div className="row">
                {selectedEmployee && (
                  <Calendar
                    onChange={handleDateChange}
                    value={selectedDate}
                    tileContent={tileContent}
                    tileDisabled={isDateDisabled}
                    minDetail="month"
                    minDate={firstDayOfMonth}
                    maxDate={lastDayOfMonth}
                    className="custom-calendar"
                    onClickDay={handleModalCalenderEmpleadoClick}
                  />
                )}
              </div>
          </div>
      
      {showModal && (
        <CalendarioEmpleadoModal
          show={showModal} // Pasar el estado showModal al modal
          handleClose={handleModalClose} // Pasar la función para cerrar el modal
          employeeData={employeeForSelectedDate}
        />
      )}
 */}
    </div>
  );
};

export default CalendarioEmployee;


