import { React, useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Button} from "react-bootstrap";
import axios from 'axios';
import '../css/ResetPassword.css';
import imagen from '../image/S-Softnik.png';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const url = 'https://www.softnik.live/auth';
  const history = useNavigate();
  
  // Obtener la URL actual
  const currentUrl = window.location.href;

  // Buscar el índice donde empieza el token
  const tokenStartIndex = currentUrl.indexOf('resetpassword?') + 'resetpassword?'.length;

  // Obtener el token de la URL
  const sessionToken = currentUrl.substring(tokenStartIndex);

  if (!sessionToken) {
    setMessage('Token de autorización no encontrado en la URL.');
    return;
  }
  const resetPassword = () => {

    if (password != confirmPassword) {
      setMessage('Las contraseñas no coinciden.');
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        'Content-Type': 'application/json' 
      }
    };
    const queryParams = new URLSearchParams({ pass: password }).toString();
    axios.post(url + '/updatePass?'+ queryParams, null, config)
      .then(response => {
        console.log(response.data)
        setPassword(response.data); // Guardar los empleados en el estado
        setMessage('Contraseña modificada correctamente');
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 400 || error.response.status === 401 ) setMessage("Error al modificar contraseña.",error.response.data);
        else{}
      });
    
  };
  const navigateToLogin = () => {
    history('/');
  };

  return (
    <div className='login-container' style={{background: 'linear-gradient(to bottom right, #2A9AB6 0%, #a33fac 100%)'}}>
    <div>
      <center>
      <img src={imagen} alt="Logo" /><br /><br />
      <h3>Restablecer Contraseña</h3>
      <form>
        <input className="rounded-input" type="password" placeholder="Contraseña" value={password} onChange={e => setPassword(e.target.value)} required /><br /><br />
        <input className="rounded-input" type="password" placeholder="Confirmar contraseña" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required /><br /><br />
        <Button
          className="button"
          variant="primary"
          onClick={resetPassword}
        >Restablecer Contraseña
        </Button>
      </form>

      <p>{message}</p>
      <Button
        className="button"
        variant="secondary"
        onClick={navigateToLogin}
      >
        Volver al inicio de sesión
      </Button>
      </center>
    </div>
    </div>
  );
}

export default ResetPassword;