// AgregarPuestoModal.js
import React, { useMemo, useState, useContext } from 'react';
import '../../css/AgregarPuesto.css';
import Places from '../../components/Map';
import Button from '../../components/Button';
import { postPuesto, putPuesto } from '../../utils/peticionesApi';
import { NewContext } from "../../utils/ContextProvider";

const AgregarPuestoModal = ({ nombreObjetivo, idO, setModalPuesto, puesto, setPuesto }) => {
  const center = useMemo(() => ({ lat: -38.006221572844225, lng: -57.544574920654284, }), []);
  const [selected, setSelected] = useState(puesto ? { lat: puesto.latitude, lng: puesto.longitude } : center);
  const [namePuesto, setNamePuesto] = useState(puesto ? puesto.name : '')
  const { getAlert } = useContext(NewContext);

  const guardarPuesto = async () => {
    const dto = {
      objective_name: nombreObjetivo,
      place: {
        latitude: selected.lat,
        longitude: selected.lng,
        name: namePuesto,
        num_employees: 500,
        objective_id: idO
      }
    }
    try {
      let respuesta = null
      if (puesto) {
        const dtoUpdate = {
          id: puesto.id,
          latitude: selected.lat,
          longitude: selected.lng,
          name: namePuesto,
          num_employees: 500,
          objective_id: idO
        }
        const response = await putPuesto(dtoUpdate)
        setPuesto(null)
      } else {
        const response = await postPuesto(dto);
        if (response.status === 200) {
          getAlert({ msj: "Puesto agregado con éxito.", tipe: 'succes' });
        } else {
          getAlert({ msj: response.data.message })
        }
      }
      setModalPuesto(false)
    } catch (error) {
      console.log(error)
    }

  }

  return (
    <div className='contenedorAgregarPuesto'>
      <p className='titulo'>{puesto ? 'Editar puesto' : 'Agregar puesto'}</p>
      <div className='contenedorFormAgregarPuesto'>
        <div className='contenedorInputAgregarPuesto'>
          <div>
            <p className='textAgregarPuesto'>Nombre del puesto:</p>
            <input className='inputAgregarPuesto' type='text' placeholder='' value={namePuesto} onChange={(e) => setNamePuesto(e.target.value)} />
          </div>
          <div>
            <p className='textAgregarPuesto'>Longitud: <span className='textLatLgn'>{selected.lng}</span></p>
            <p className='textAgregarPuesto'>Latitud: <span className='textLatLgn'>{selected.lat}</span></p>
          </div>
          <div className='contenedorBotonesAgregarPuesto'>
            <Button msj='Cancelar' tipe='sinBg' onClick={() => setModalPuesto(false)} />
            <Button msj='Guardar puesto' onClick={guardarPuesto} />
          </div>
        </div>
        <Places selected={selected} setSelected={setSelected} />
      </div>
    </div>
  )
};

export default AgregarPuestoModal;
