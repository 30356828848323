import axios from "axios"

export const axiosInterceptor = () => {
    const updateHeaders = (request) =>{
        const  token = sessionStorage.getItem("access_token")
        const newHeader = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
        request.headers = {...request.headers,...newHeader}
        return request
    }
    axios.interceptors.request.use((req)=> {
        if(req.url?.includes('login')) return req
        return updateHeaders(req)
    })

    axios.interceptors.response.use((response) => {
        return response
    }, (error) => {
        if(error.response.status === 403){
            sessionStorage.removeItem("access_token")
            window.location.href = "/"
        }
        return Promise.reject(error)
    })
}