import { React, useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import "../css/Jornada.css";
import "../css/Stepper.css";
import axios from "axios";
import CameraComponent from "../components/CameraComponent";
import { NewContext } from "../utils/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import Button from "../components/Button";
import { postAsistencia } from "../utils/peticionesApi";
import Logo from '../image/Logo-jornada.svg'; // Ajusta la ruta según sea necesario

const Jornada = () => {
    const { getCompany, getAlert, getId } = useContext(NewContext);
    const [showCamera, setShowCamera] = useState(false);
    const [photoData, setPhotoData] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const ulrPicturePersonal = "https://www.softnik.cloud/awsPicture";

    const company = getCompany;
    const GOOGLE_API_KEY = 'AIzaSyCcOOP3zddZONQE7PgcuA9ZOiz-SH7v1Fs';

    const formatFecha = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatFechaAsistencia = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    const handleSubmit = async (actionType) => {
        if (imageUrl === null && actionType === 'Iniciar') {
            getAlert({ msj: 'Por favor, toma la foto antes de continuar.', tipe: 'warning' });
            return;
        }
        const today = new Date();
        const formattedDate = formatFecha(today);
        const formattedDateAssistent = formatFechaAsistencia(today);
        const action = actionType === "Iniciar" ? "I" : "E";
        try {
            const geoLocation = await getLocation();
            console.log(geoLocation)
            const formDataToSendAssistent = {
                date: formattedDateAssistent,
                latitude: geoLocation.latitude,
                longitude: geoLocation.longitude,
                token: getId,
                type: action,
            };

            // Realizar la solicitud para guardar la asistencia
            const response = await guardaAsistencia(formDataToSendAssistent);
            if (!response) return
            // Verificar si la respuesta es un código 200
            if (response.status === 200) {
                await guardarFotoPresentismo(getId, formattedDate, action, photoData);
                setImageUrl(null);
            } else {
                // Si la respuesta no es un código 200, mostrar un mensaje de error
                getAlert({ msj: `Hubo un problema al guardar la asistencia.`, tipe: 'error' });
                console.log(response.statusText);
            }
        } catch (error) {
            console.log(error)
            if (error.code === error.PERMISSION_DENIED) {
                getAlert({ msj: 'Por favor, habilita la ubicación para continuar.', tipe: 'warning' });
            } else {
                getAlert({ msj: 'Error al obtener la ubicación. Intenta nuevamente.', tipe: 'error' });
            }
        }
    };

    const guardarFotoPresentismo = async (id, date, type, file) => {
        if (!file) {
            getAlert({ msj: "Selecciona un archivo antes de importar.", tipe: 'warning' });
            return;
        }
        try {
            const formData = new FormData();
            formData.append("file", file);
            const response = await axios.post(
                ulrPicturePersonal + "/uploadPicture",
                formData,
                {
                    params: {
                        company: company,
                        date: date,
                        type: type,
                        uuid: getId,
                    },
                }
            );
            if (response.status === 200) {
                console.log("Imagen importada con éxito.");
            } else {
                console.error("Hubo un problema al importar la imagen.");
            }
        } catch (error) {
            console.error("Error al importar la imagen:", error);
        }
    };

    // Carga la API de Geolocalización de Google para JavaScript
    /*   function loadGoogleMaps(callback) {
           // Verificar si el script ya existe
          if (document.getElementById('googleMaps')) {
              callback();
              return;
          }
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
          script.async = true;
          script.defer = true;
          script.onload = callback;
          document.head.appendChild(script);
      } */

    // Función para obtener la ubicación con precisión utilizando la API de Geolocalización de Google
    const getLocation = async () => {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line
            /* if (typeof google === 'undefined') {
                reject(new Error('Google Maps JavaScript API is not loaded.'));
                return;
            } */

            /*  const geocoder = new google.maps.Geocoder(); */
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const { latitude, longitude } = position.coords;
                        resolve({ latitude, longitude });
                        /* 
                        const latLng = new google.maps.LatLng(latitude, longitude);
                        geocoder.geocode({ 'location': latLng }, (results, status) => {
                            if (status === 'OK') {
                                if (results[0]) {
                                    const { lat, lng } = results[0].geometry.location;
                                    resolve({ latitude: lat(), longitude: lng() });
                                } else {
                                    reject(new Error('No results found'));
                                }
                            } else {
                                reject(new Error(`Geocoder failed due to: ${status}`));
                            }
                        }); */
                    },
                    error => {
                        if (error.code === error.PERMISSION_DENIED) {
                            getAlert({ msj: 'Por favor, habilita la ubicación para continuar.', tipe: 'warning' });
                        } else {
                            getAlert({ msj: 'Error al obtener la ubicación. Intenta nuevamente.', tipe: 'error' });
                        }
                        reject(error);
                    }
                );
            } else {
                reject(new Error('Geolocation is not supported by this browser.'));
            }
        });
    };

    /*   useEffect(()=>{
          // Cargar Google Maps API y luego obtener la ubicación
          loadGoogleMaps(async () => {
              try {
                  const location = await getLocation();
                  console.log('Location obtained:', location);
              } catch (error) {
                  console.error('Could not get location:', error);
              }
          });    
      },[]) */


    const guardaAsistencia = async (dto) => {
        try {
            const response = await postAsistencia(dto)

            if (response.status === 200) {
                if (!response.data.success) {
                    getAlert({ msj: "Asistencia registrada con éxito.", tipe: 'succes' });
                }
                return response;
            } else {
                getAlert({ msj: "Hubo un problema al registrar asistencia. Intente mas tarde", tipe: 'error' });
            }
        } catch (error) {
            getAlert({ msj: `Error al agregar el asistencia: ${error.response.data.message}`, tipe: 'error' });
            console.log(error);
        }
    };

    const handlePhotoCapture = (imageSrc) => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, "0");
        const day = today.getDate().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;

        const byteCharacters = atob(imageSrc.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/jpeg" });

        const file = new File([blob], `${formattedDate}.jpg`, {
            type: "image/jpeg",
        });

        setPhotoData(file);
        const formData = new FormData();
        formData.append("file", file);

        setImageUrl(URL.createObjectURL(file));
        setShowCamera(false);
    };

    const handleCameraButtonClick = () => {
        setShowCamera(true);
    };

    return (
        <div className="jornadacontainer">
            <p className="titulo">Jornada</p>
            <div className="contenedor-jornada">
                <div className="ellipse-container">
                    {imageUrl === null ? (
                        <div className="ellipse">
                            <img
                                src={Logo}
                            />
                            <button
                                className="btn-img-jornada"
                                onClick={handleCameraButtonClick}
                            >
                                <div className="camara-jornada">
                                    <FontAwesomeIcon
                                        icon={faCamera}
                                        className="icon-camara-jornada"
                                    />
                                </div>
                            </button>
                        </div>
                    ) : (
                        <div className="ellipse">
                            <img className="img-jornada" src={imageUrl} alt="img-camara" />
                        </div>
                    )}
                    {showCamera && <CameraComponent onPhotoCapture={handlePhotoCapture} />}
                </div>
                <div className="contenedor-texto-jornada">
                    <p className="titulo-foto-jornada">Vamos a tomar tu foto de hoy</p>
                    <p className="subtitulo-foto-jornada">
                        Ubicate en un espacio con buena luz, y procurá que la foto no salga
                        movida.
                    </p>
                </div>
                <div>
                    <div className="buttons-jornada">

                        <Button msj="INICIAR JORNADA" onClick={() => handleSubmit("Iniciar")} />

                        <Button msj="FINALIZAR JORNADA" tipe="red" onClick={() => handleSubmit("Finalizar")} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Jornada;