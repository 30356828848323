import React, { useEffect, useState } from 'react'
import '../css/Button.css'

const Button = ({msj, tipe = 'default', onClick, children}) => {
    const [getTipe, setTipe] = useState(tipe)

    useEffect(()=>{
        setTipe(tipe)
    }, [tipe])

  return (
    <button onClick={onClick} className={`${getTipe} buttonComponent dm-sans`}>
      {children}
      {msj}
    </button>
  )
}

export default Button
