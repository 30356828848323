import React from 'react'
import '../../css/OptionComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faXmark, faPlus, faCalendarPlus } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'

const OptionCompoentetVisitantes = ({value, onChange, setModalView, modalView, setVisitaUpdate}) => {


  return (
        <div className='contenedorOptions'>
          <div className='contenedorInputOptionRegisters'>
            <input className='inputComponentRegisters' type='text' placeholder='Buscar por nombre, apellido o DNI' value={value} onChange={onChange}/>
          </div>
          <div>
            <button className='button-icons-options' onClick={()=>{setVisitaUpdate(null); setModalView(!modalView)}}>
              {
                !modalView ?
                  <FontAwesomeIcon icon={faPlus} className='icon-options'/>
                :
                  <FontAwesomeIcon icon={faXmark} className='icon-options-close'/>
              }
            </button>
          </div>
        </div>
  )
}

export default OptionCompoentetVisitantes
