import React, { useState } from 'react';
import '../../css/EditEmpleado.css';
import Button from '../../components/Button';
import { putPerfil, putUsuario } from '../../utils/peticionesApi';

const initialState = {
  address_dto: {
    apartament: "",
    floor: "",
    number: "",
    street: "",
  },
  cell_phone: "",
  email: "",
  emergency_contact: "",
  name_emergency_contact: "",
  username: "",
  appUserRoles: "",
};

const EditEmpleado = ({ setEditMode, persona, id, usuario, setPersona, setUsuario }) => {
  console.log("Usuario recibido en EditEmpleado:", usuario);

  const [editedInfo, setEditedInfo] = useState({
    address_dto: {
      apartament: persona?.address?.apartament || "",
      floor: persona?.address?.floor || "",
      number: persona?.address?.number || "",
      street: persona?.address?.street || "",
    },
    cell_phone: persona?.cell_phone || "",
    email: persona?.email || "",
    emergency_contact: persona?.emergency_contact || "",
    name_emergency_contact: persona?.name_emergency_contact || "",
    username: usuario?.username || "", // Asegúrate de que este valor llega correctamente
    appUserRoles: usuario?.appUserRoles || "", // Asegúrate de que este valor llega correctamente
  });

  const handleSaveClick = async () => {
    try {
      // Dividir datos entre perfil y usuario
      const perfilData = {
        address_dto: editedInfo.address_dto,
        cell_phone: editedInfo.cell_phone,
        email: editedInfo.email,
        emergency_contact: editedInfo.emergency_contact,
        name_emergency_contact: editedInfo.name_emergency_contact,
      };

      const usuarioData = {
        username: editedInfo.username,
        appUserRoles: editedInfo.appUserRoles,
      };

      // Guardar cambios en el perfil
      if (Object.values(perfilData).some((value) => value !== "")) {
        const perfilResponse = await putPerfil(id, perfilData);
        setPersona(perfilResponse.data);
      }

      // Guardar cambios en el usuario
      if (Object.values(usuarioData).some((value) => value !== "")) {
        const usuarioResponse = await putUsuario(usuario.id, usuarioData);
        setUsuario(usuarioResponse.data);
      }

      setEditMode(false);
    } catch (error) {
      console.error("Error al guardar los cambios:", error);
    }
  };

  const handleInputChange = (e, field, nestedField) => {
    setEditedInfo((prevInfo) => ({
      ...prevInfo,
      [field]: nestedField
        ? { ...prevInfo[field], [nestedField]: e.target.value }
        : e.target.value,
    }));
  };

  return (
    <div className="contenedor-editEmpleado">
      <p className="titulo-edit-empleado">Editar empleado</p>

      {/* Dirección */}
      <div className="borde-bottom-edit">
        <p className="subtitulo-edit-empleado">Dirección</p>
        <div className="contenedorDireccion">
          {["street", "number", "floor", "apartament"].map((field) => (
            <div key={field}>
              <p>{field.charAt(0).toUpperCase() + field.slice(1)}:</p>
              <input
                className="input-edit-empleado"
                value={editedInfo.address_dto[field]}
                onChange={(e) => handleInputChange(e, 'address_dto', field)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Contacto */}
      <div className="borde-bottom-edit">
        <p className="subtitulo-edit-empleado">Contacto</p>
        <div className="contenedorDireccion">
          {["cell_phone", "email", "name_emergency_contact", "emergency_contact"].map((field) => (
            <div key={field}>
              <p>{field.replace("_", " ").toUpperCase()}:</p>
              <input
                className="input-edit-empleado"
                value={editedInfo[field]}
                onChange={(e) => handleInputChange(e, field)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* Usuario */}
      <div className="borde-bottom-edit">
        <p className="subtitulo-edit-empleado">Usuario</p>
        <div className="contenedorDireccion">
          {["username", "appUserRoles"].map((field) => (
            <div key={field}>
              <p>{field.toUpperCase()}:</p>
              <input
                className="input-edit-empleado"
                value={editedInfo[field]}
                onChange={(e) => handleInputChange(e, field)}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="contenedor-button-edit-empleado">
        <Button msj="Cancelar" tipe="sinBg" onClick={() => setEditMode(false)} />
        <Button msj="Guardar" onClick={handleSaveClick} />
      </div>
    </div>
  );
};

export default EditEmpleado;