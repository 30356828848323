import React from 'react'
import '../css/OptionComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faDownload, faDiagramNext, faXmark, faBug, faSquarePollVertical } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useParams } from "react-router-dom";


const OptionComponent = ({ type, placeHolder, value, onChange, setModalView, modalView, setModalViewExport, modalViewExport,
  setModalViewReportes, modalViewReportes, setModalViewEventos, modalViewEventos
}) => {

  const navigate = useNavigate();

  return (
    <div className='contenedorOptions'>
      <input className='inputComponent' type={type} placeholder={placeHolder} value={value} onChange={onChange} />
      <div>
        <button className='button-icons-options' onClick={() => navigate("/relojEventos")}>
          {
            !modalViewExport ?
              <FontAwesomeIcon icon={faDiagramNext} className='icon-options' />
              :
              <FontAwesomeIcon icon={faXmark} className='icon-options-close' />
          }

        </button>
        <button className='button-icons-options' onClick={() => navigate("/relojReportes")}>
          {
            !modalViewExport ?
              <FontAwesomeIcon icon={faBug} className='icon-options' />
              :
              <FontAwesomeIcon icon={faXmark} className='icon-options-close' />
          }

        </button>
        <button className='button-icons-options' onClick={() => navigate("/relojResumentEmpleados")}>
          {
            !modalViewExport ?
              <FontAwesomeIcon icon={faSquarePollVertical} className='icon-options' />
              :
              <FontAwesomeIcon icon={faXmark} className='icon-options-close' />
          }

        </button>
        <button className='button-icons-options' onClick={() => { setModalView(!modalView); setModalViewExport(false) }}>
          {
            !modalView ?
              <FontAwesomeIcon icon={faCalendar} className='icon-options' />
              :
              <FontAwesomeIcon icon={faXmark} className='icon-options-close' />
          }
        </button>
        <button className='button-icons-options' onClick={() => { setModalViewExport(!modalViewExport); setModalView(false) }}>
          {
            !modalViewExport ?
              <FontAwesomeIcon icon={faDownload} className='icon-options' />
              :
              <FontAwesomeIcon icon={faXmark} className='icon-options-close' />
          }

        </button>
      </div>
    </div>
  )
}

export default OptionComponent
