import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../css/InformacionObjetivoModal.css";
import {
  deleteObjetivo,
  deletePlaceObject,
  getObjetivo,
  getPlaceObject,
} from "../utils/peticionesApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleLeft,
  faPenToSquare,
  faPlus,
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";
import AgregarPuestoModal from "../forms/Objetivo/AgregarPuestoModal";
import AgregarObjetivoModal from "../forms/Objetivo/AgregarObjetivoModal";
import { NewContext } from "../utils/ContextProvider";

const InformacionObjetivoModal = () => {
  const [puestos, setPuestos] = useState();
  const [puesto, setPuesto] = useState();
  const [objetivo, setObjetivo] = useState();
  const [idPlace, setIdPlace] = useState();
  const [modalDeletePuesto, setModalDeletePuesto] = useState(false);
  const [modalPuesto, setModalPuesto] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [modalModificarPuesto, setModalModificarPuesto] = useState(false)
  const { getAlert } = useContext(NewContext);
  const [modalDeleteObjetivo, setModalDeleteObjetivo] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    listarPuestos();
  }, [modalPuesto, modalDeletePuesto, modalModificarPuesto]);

  useEffect(() => {
    if (puestos) {
      listarObjetivos();
    }
  }, [puestos, menuVisible]);

  const listarPuestos = async () => {
    try {
      const respuesta = await getPlaceObject(id);
      setPuestos(respuesta.data);
    } catch (error) {
      console.log(error);
    }
  };

  const listarObjetivos = async () => {
    if (!puestos) return;
    try {
      const respuesta = await getObjetivo(id);
      setObjetivo(respuesta.data);
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarPuesto = async () => {
    try {
      await deletePlaceObject(idPlace);
      setModalDeletePuesto(false);
      listarObjetivos();
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarEmpresa = async () => {
    try {
      const response = await deleteObjetivo(id)
      if (response.status === 200) {
        getAlert({ msj: "Empresa Eliminada con éxito.", tipe: 'succes' });
      } else {
        getAlert({ msj: response.data.message })
      }
      navigate('/objetivos')
    } catch (error) {
      console.log(error)
    }
  }

  const modificarItem = item => {
    setPuesto(item)
    setModalModificarPuesto(true)
  }

  const confirmarEliminarObjetivo = () => {
    setModalDeleteObjetivo(true);
  };

  if (menuVisible) return <AgregarObjetivoModal setMenuVisible={setMenuVisible} menuVisible={menuVisible} objetivo={objetivo} />
  if (modalPuesto) return <AgregarPuestoModal nombreObjetivo={objetivo?.name} idO={objetivo?.id} setModalPuesto={setModalPuesto} />
  if (modalModificarPuesto) return <AgregarPuestoModal nombreObjetivo={objetivo?.name} idO={objetivo?.id} setModalPuesto={setModalModificarPuesto} puesto={puesto} setPuesto={setPuesto} />

  return (
    <div className="contenedorInfoObjetivo">
      <div>
        <button
          onClick={() => navigate("/objetivos")}
          className="buttonVolverObj"
        >
          <FontAwesomeIcon icon={faCircleLeft} />
          Volver
        </button>
        <div className="contenedorTituloPuesto">
          <p className="NombreObjetivo">{objetivo?.name}</p>
          <div>
            <Button tipe="red" msj="Eliminar Empresa" onClick={confirmarEliminarObjetivo}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </div>
        <div className="contenedorInfo">
          <div className="contenedorInfoDireccion">
            <div className="contenedorTituloPuesto">
              <p className="tituloInfo">Direccion</p>
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="iconoAgregarPuesto"
                onClick={() => setMenuVisible(true)}
              />
            </div>
            <div className="contenedorInfoItems">
              <div className="contenedorInfoItem">
                <p className="tituloItemObjetivo">Calle</p>
                <p>{objetivo?.address.street}</p>
              </div>
              <div className="contenedorInfoItem">
                <p className="tituloItemObjetivo">Numero</p>
                <p>{objetivo?.address.number}</p>
              </div>
              <div className="contenedorInfoItem">
                <p className="tituloItemObjetivo">Piso</p>
                <p>{objetivo?.address.floor}</p>
              </div>
              <div className="contenedorInfoItem">
                <p className="tituloItemObjetivo">Departamento</p>
                <p>{objetivo?.address.apartment}</p>
              </div>
            </div>
          </div>
          <div className="contenedorInfoDireccion">
            <div className="contenedorTituloPuesto">
              <p className="tituloInfo">Puestos</p>
              <FontAwesomeIcon icon={faPlus} className="iconoAgregarPuesto" onClick={() => setModalPuesto(!modalPuesto)} />
            </div>
            <div className="contenedorPuestos">
              {puestos && puestos.length ? (
                puestos.map((item) => {
                  return (
                    <div className="contenedorPueso" key={item.id}>
                      <div className="contenedorItemPuesto">
                        <p className="tituloItemObjetivo">Puesto</p>
                        <p>{item.name}</p>
                      </div>
                      <div className="contenedorBotonesPuesto">
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className="buttonEditPuesto"
                          onClick={() => modificarItem(item)}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="buttonDeletePuesto"
                          onClick={() => {
                            setModalDeletePuesto(!modalDeletePuesto);
                            setIdPlace(item.id);
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="contenedorPueso">
                  <p>Sin puestos</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {modalDeleteObjetivo && (
        <div className="modalDelete">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="iconoDelete"
          />
          <p>¿Estás seguro de eliminar este objetivo?</p>
          <div className="contenedorButonsDelete">
            <Button
              tipe="sinBg"
              msj="Cancelar"
              onClick={() => setModalDeleteObjetivo(false)}
            />
            <Button msj="Confirmar" onClick={eliminarEmpresa} />
          </div>
        </div>
      )}

      {modalDeletePuesto && (
        <div className="modalDelete">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="iconoDelete"
          />
          <p>Estas seguro de eliminar el puesto?</p>
          <div className="contenedorButonsDelete">
            <Button
              tipe="sinBg"
              msj="Cancelar"
              onClick={() => setModalDeletePuesto(!modalDeletePuesto)}
            />
            <Button msj="Confirmar" onClick={eliminarPuesto} />
          </div>
        </div>
      )}
    </div>
  );
};

export default InformacionObjetivoModal;
