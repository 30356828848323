import React, { useContext, useEffect, useState, useMemo } from "react";
import "react-calendar/dist/Calendar.css";
import "../css/Solicitudes.css";
import CustomPagination from "../components/CustomPagination";
import { NewContext } from "../utils/ContextProvider";
import AgregarSolicitudModal from "../forms/Solicitud/AgregarSolicitudModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft, faTrash, faPenToSquare, faFileArrowDown, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { getReason, getRequest, getRequestById, deleteRequestObject, getExportRequest } from "../utils/peticionesApi";
import OptionComponentSolicitudes from "../components/OptionComponentSolicitudes";
import Button from "../components/Button";
import { useParams } from "react-router-dom";

const Solicitudes = () => {
  const { getToken, getRol, getId } = useContext(NewContext);
  const [request, setRequest] = useState([]);
  const [reasons, setReason] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [menuVisible, setMenuVisible] = useState(false);
  const [showModalSolicitud, setShowModalSolicitud] = useState(false);
  const [modalDeleteSolicitud, setModalDeleteSolicitud] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [selectedSolicitud, setSelectedSolicitud] = useState(null);
  const { id } = useParams()

  useEffect(() => {
    if (getToken && getId) {
      listarRequest();
    }
  }, [getToken, getId, showModalSolicitud]);

  useEffect(() => {
    listarReason();
  }, []);

  const closeMenu = () => setMenuVisible(false);
  const openModalSolicitud = () => {
    setSelectedSolicitud(null);
    setShowModalSolicitud(true);
  };

  const closeModalSolicitud = () => setShowModalSolicitud(false);

  const listarRequest = async () => {
    let response;
    try {
      if (id) {
        // Si se proporciona un ID en la URL, obtener las solicitudes para ese empleado
        response = await getRequestById(id);
      } else if (getRol === "ROLE_ADMIN") {
        // Si el usuario es administrador y no hay ID, obtener todas las solicitudes
        response = await getRequest();
      } else {
        // Si no es administrador y no hay ID, obtener las solicitudes del usuario actual
        response = await getRequestById(getId);
      }

      if (response && response.data) {
        setRequest(response.data);
      } else {
        console.error("Error: La respuesta no contiene datos.");
      }
    } catch (error) {
      if (error.response) {
        console.error(`Error en la solicitud: ${error.response.status} - ${error.response.statusText}`);
      } else {
        console.error("Error al hacer la solicitud:", error.message);
      }
    }
  };

  const formatDate = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) {
      return "Fecha inválida";
    }

    const [year, month, day] = dateArray;
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const listarReason = async () => {
    const response = await getReason();
    setReason(response.data);
  };

  const filtradoSolicitudes = useMemo(() => {
    return request?.filter((r) => {
      const searchLowerCase = searchTerm.toLowerCase();
      return (
        r.employee_first_name?.toLowerCase().includes(searchLowerCase) || // Filtrar por nombre del empleado
        r.employee_last_name?.toLowerCase().includes(searchLowerCase) ||  // Filtrar por apellido del empleado
        reasons.find(reason => reason.id === r.id_reason)?.reason?.toLowerCase().includes(searchLowerCase) || // Filtrar por motivo
        formatDate(r.request_since).includes(searchLowerCase) || // Filtrar por fecha formateada
        r.observations_request?.toLowerCase().includes(searchLowerCase) || // Filtrar por observaciones
        (r.active ? "aprobado" : "pendiente").toLowerCase().includes(searchLowerCase) // Filtrar por estado
      );
    });
  }, [searchTerm, request, reasons]);
  
  const solicitudesPorPagina = 15;
  const paginatedSolicitudes = useMemo(() => {
    const start = currentPage * solicitudesPorPagina;
    const end = start + solicitudesPorPagina;
    return filtradoSolicitudes.slice(start, end);
  }, [currentPage, filtradoSolicitudes]);

  const handleEditClick = (request) => {
    setSelectedSolicitud(request);
    setShowModalSolicitud(true);
  };

  const handleDeleteClick = (request) => {
    setSelectedRequestId(request.id_request);
    setModalDeleteSolicitud(true);
  };

  const handleDeleteSolicitud = async () => {
    try {
      if (selectedRequestId) {
        await deleteRequestObject(selectedRequestId);
        setModalDeleteSolicitud(false);
        listarRequest();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadClick = async (request) => {
    console.log(request.id_request);
    setSelectedRequestId(request.id_request);

    try {
      await getExportRequest(request.id_request);
      console.log('Export successful');
    } catch (error) {
      console.error('Export failed:', error);
    }
  };

  const TableRow = ({ request }) => {
    const motivo = reasons.find((reason) => reason.id === request.id_reason);
    const motivoName = motivo ? motivo.reason : "Motivo Desconocido";

    return (
      <tr>
        <td>{request.employee_first_name + " " + request.employee_last_name}</td>
        <td>{motivoName}</td>
        <td>{formatDate(request.request_since)}</td>
        <td>{request.observations_request}</td>
        <td style={{ color: request.active ? "#009188" : "#EC5B5B" }}>
          <span className={`${request.active ? "itemActive" : "itemInactive"}`}>
            {request.active ? "Aprobado" : "Pendiente"}
          </span>
        </td>
        <td className="contenedor-item">
          <FontAwesomeIcon icon={faPenToSquare} className="icon-edit" onClick={() => handleEditClick(request)} />
          <FontAwesomeIcon icon={faTrash} className="icon-salida" onClick={() => handleDeleteClick(request)} />
          <FontAwesomeIcon icon={faFileArrowDown} className="icon-File" onClick={() => handleDownloadClick(request)} />
        </td>
      </tr>
    );
  };

  const handleSubmitSolicitud = async (formData) => {
    try {
      if (formData.id_request) {
        console.log("Editando solicitud:", formData);
      } else {
        console.log("Agregando nueva solicitud:", formData);
      }

      listarRequest();
      setShowModalSolicitud(false);

    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    }
  };

  return (
    <div className="contenedor-solicitudes">
      {id && (
        <button
          onClick={() => window.history.back()}
          className="buttonVolverObj"
        >
          <FontAwesomeIcon icon={faCircleLeft} />
          Volver
        </button>
      )}
      <p className="titulo">
        {showModalSolicitud ? (selectedSolicitud ? "Modificar solicitud" : "Agregar solicitud") : "Solicitudes"}
      </p>
      {showModalSolicitud ? (
        <AgregarSolicitudModal
          reasons={reasons}
          rol={getRol}
          solicitud={selectedSolicitud}
          closeModalSolicitud={closeModalSolicitud}
          handleSubmitSolicitud={handleSubmitSolicitud}
        />
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <OptionComponentSolicitudes
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              agregarFunction={openModalSolicitud}
            />
          </div>
          <div className="data-table">
            <table>
              <thead>
                <tr>
                  <th>Solicitante</th>
                  <th>Motivo</th>
                  <th>Fecha</th>
                  <th>Observaciones</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {paginatedSolicitudes.length === 0 ? (
                  <tr>
                    <td colSpan="6">No se encontraron resultados.</td>
                  </tr>
                ) : (
                  paginatedSolicitudes.map((request, index) => (
                    <TableRow key={index} request={request} />
                  ))
                )}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div className="d-flex justify-content-center mt-4">
              <CustomPagination
                currentPage={currentPage}
                totalPages={Math.ceil(filtradoSolicitudes.length / solicitudesPorPagina)}
                setCurrentPage={setCurrentPage}
                hasNextPage={currentPage < Math.ceil(filtradoSolicitudes.length / solicitudesPorPagina) - 1}
              />
            </div>
          </div>
        </>
      )}

      {modalDeleteSolicitud && (
        <div className="modalDelete">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="iconoDelete"
          />
          <p>¿Estas seguro de eliminar esta solicitud?</p>
          <div className="contenedorButonsDelete">
            <Button
              tipe="sinBg"
              msj="Cancelar"
              onClick={() => setModalDeleteSolicitud(!modalDeleteSolicitud)}
            />
            <Button msj="Confirmar" onClick={handleDeleteSolicitud} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Solicitudes;