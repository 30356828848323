import { useEffect, useState, useContext } from 'react';
import '../css/Visitantes.css';
import OptionCompoentetVisitantes from '../forms/Visitantes/OptionCompoentetVisitantes';
import AgregarVisitante from '../forms/Visitantes/AgregarVisitante';
import { deleteVisit, getPersona, getVisit, putVisit } from '../utils/peticionesApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalkingArrowRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NewContext } from "../utils/ContextProvider";

const Visitantes = () => {
  const [modalView, setModalView] = useState(false);
  const [visitas, setVisitas] = useState([]);
  const [visitaUpdate, setVisitaUpdate] = useState();
  const [empleados, setEmpleados] = useState({});
  const { getAlert, getToken } = useContext(NewContext);
  const [searchTerm, setSearchTerm] = useState("");

  // Cargar las visitas y empleados al montar el componente
  useEffect(() => {
    if (!getToken) return;
    listarVisitas();
    findEmpleados();
  }, [getToken]);

  // Función para listar las visitas
  const listarVisitas = async () => {
    try {
      const resp = await getVisit();
      setVisitas(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Función para obtener empleados
  const findEmpleados = async () => {
    const res = await getPersona();
    if (res && res.data.content) {
      const empleadosMap = res.data.content.reduce((acc, empleado) => {
        acc[empleado.id] = `${empleado.person.name} ${empleado.person.lastname}`;
        return acc;
      }, {});
      setEmpleados(empleadosMap);
    }
  };

  // Filtrar las visitas según el término de búsqueda
  const filteredVisitas = visitas?.filter((item) => {
    const fullName = `${item.name} ${item.lastname}`.toLowerCase();
    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      item.national_id.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const formatTwoDigits = (num) => (num < 10 ? '0' + num : num);

  const getFormattedDateTime = () => {
    const now = new Date();
    const day = formatTwoDigits(now.getDate());
    const month = formatTwoDigits(now.getMonth() + 1);
    const year = now.getFullYear();
    const hours = formatTwoDigits(now.getHours());
    const minutes = formatTwoDigits(now.getMinutes());
    const seconds = formatTwoDigits(now.getSeconds());
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const salidaVisitante = async (item) => {
    const datos = { ...item };
    datos.present_on_site = false;
    datos.exit_date_time = getFormattedDateTime();
    datos.entry_date_time = `${datos.entry_date} ${datos.entry_time}`;

    delete datos.entry_date;
    delete datos.entry_time;

    try {
      const response = await putVisit(datos);
      if (response.status === 200) {
        getAlert({ msj: "Salida del visitante con éxito.", tipe: 'succes' });
      } else {
        getAlert({ msj: response.data.message });
      }
      listarVisitas();
    } catch (error) {
      console.error("Error al realizar la solicitud:", error.response?.data || error.message);
    }
  };

  const updateVisita = (item) => {
    setVisitaUpdate(item);
    setModalView(true);
  };

  const deleteVisita = async (id) => {
    try {
      const response = await deleteVisit(id);
      if (response.status === 200) {
        getAlert({ msj: "Visitante eliminado con éxito.", tipe: 'succes' });
      } else {
        getAlert({ msj: response.data.message });
      }
      listarVisitas();
    } catch (error) {
      console.error(error);
    }
  };

  const TableRow = ({ item, index }) => (
    <tr key={index}>
      <td onClick={() => updateVisita(item)}>{item.name}</td>
      <td onClick={() => updateVisita(item)}>{item.lastname}</td>
      <td onClick={() => updateVisita(item)}>{item.national_id}</td>
      <td onClick={() => updateVisita(item)}>{item.address}</td>
      <td onClick={() => updateVisita(item)}>{item.entry_date}</td>
      <td onClick={() => updateVisita(item)}>{item.entry_time}</td>
      <td onClick={() => updateVisita(item)}>{empleados[item.uuid_employee] || ''}</td>
      <td onClick={() => updateVisita(item)}>
        <div className={`ingreso-container ${!item.present_on_site ? 'salida' : ''}`}>
          {item.present_on_site ? "Ingreso" : "Salida"}
        </div>
      </td>
      <td className='contenedor-item-salida'>
        {item.present_on_site ? (
          <FontAwesomeIcon onClick={() => salidaVisitante(item)} icon={faPersonWalkingArrowRight} className='icon-salida' />
        ) : (
          <p>{item.exit_time}</p>
        )}
      </td>
      <td className='contenedor-item-eliminar'>
        <FontAwesomeIcon icon={faTrash} className='icon-salida' onClick={() => deleteVisita(item.id)} />
      </td>
    </tr>
  );

  return (
    <div className="visitantesContainer">
      <p className='titulo'>Visitantes</p>
      <OptionCompoentetVisitantes
        modalView={modalView}
        setModalView={setModalView}
        setVisitaUpdate={setVisitaUpdate}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>DNI</th>
            <th>Domicilio</th>
            <th>Fecha</th>
            <th>Entrada</th>
            <th>Visita a</th>
            <th>Ingreso</th>
            <th>Salida</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {searchTerm !== "" ? (
            filteredVisitas.length === 0 ? (
              <tr>
                <td colSpan="10">No se encontraron resultados.</td>
              </tr>
            ) : (
              filteredVisitas.map((item, index) => (
                <TableRow key={index} item={item} index={index} />
              ))
            )
          ) : visitas.length === 0 ? (
            <tr>
              <td colSpan="10">No hay visitantes.</td>
            </tr>
          ) : (
            visitas.map((item, index) => (
              <TableRow key={index} item={item} index={index} />
            ))
          )}
        </tbody>
      </table>
      {modalView && <AgregarVisitante setModalView={setModalView} visitaUpdate={visitaUpdate} listarVisitas={listarVisitas} />}
    </div>
  );
};

export default Visitantes;