import React, { useEffect, useState, useContext } from "react";
import "../../css/AgregarObjetivoModal.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getCity,
  getDistrito,
  postObjetivo,
  putObjetivo,
} from "../../utils/peticionesApi";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHashtag,
  faLocationDot,
  faHouse,
  faBuilding,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { NewContext } from "../../utils/ContextProvider";

const AgregarObjetivoModal = ({ menuVisible, setMenuVisible, objetivo }) => {
  const [distrito, setDistrito] = useState(null);
  const [ciudad, setCiudad] = useState(null);
  const { getAlert } = useContext(NewContext);

  const navigation = useNavigate();

  const listarDistrito = async () => {
    try {
      const response = await getDistrito();
      setDistrito(response.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  const listarCiudad = async () => {
    try {
      const response = await getCity();
      setCiudad(response.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    listarDistrito();
    listarCiudad();
  }, []);

  const formik = useFormik({
    initialValues: {
      address: {
        apartament: objetivo ? objetivo.address.apartament : "",
        floor: objetivo ? objetivo.address.floor : "",
        number: objetivo ? objetivo.address.number : "",
        street: objetivo ? objetivo.address.street : "",
      },
      city: {
        id_district: objetivo ? objetivo.city.id_district : "",
        name: objetivo ? objetivo.city.name : "",
        zip_code: objetivo ? objetivo.city.zip_code : "",
      },
      objective: {
        name: objetivo ? objetivo.name : "",
      },
    },
    validationSchema: Yup.object({
      address: Yup.object({
        street: Yup.string().required("Campo obligatorio"),
        number: Yup.string().required("Campo obligatorio"),
      }),
      city: Yup.object({
        name: Yup.string().required("Campo obligatorio"),
      }),
      objective: Yup.object({
        name: Yup.string().required("Campo obligatorio"),
      }),
    }),
    onSubmit: async (values) => {
      try {
        const dto = {
          address: {
            apartament: values.address.apartament || 0,
            floor: values.address.floor || 0,
            number: values.address.number || 0,
            street: values.address.street,
          },
          city: {
            id_district: values.city.id_district || 0,
            name: values.city.name,
            zip_code: values.city.zip_code || 0,
          },
          objective: {
            active: true,
            name: values.objective.name,
          },
        };

        if (objetivo) {
          const dtoUpdate = {
            ...dto,
            address: { ...dto.address, id: objetivo.address.id },
            city: { ...dto.city, id: objetivo.city.id },
            objective: { ...dto.objective, id: objetivo.id },
          };
          await putObjetivo(dtoUpdate);
          getAlert({ msj: "Empresa actualizada con éxito.", tipe: "succes" });
        } else {
          await postObjetivo(dto);
          getAlert({ msj: "Empresa agregada con éxito.", tipe: "succes" });
        }

        // Cierra el modal y luego redirige
        setMenuVisible(false);
        navigation("/objetivos");
      } catch (error) {
        getAlert({
          msj: "Ocurrió un error al guardar la empresa. Intente nuevamente.",
          tipe: "error",
        });
        console.log(error);
      }
    },
  });

  return (
    <div className="contenedorObjetivo">
      <p className="titulo">
        {objetivo ? "Editar puesto" : "Agregar Empresa"}
      </p>
      <form
        className="formularioAgregarObjetivo"
        onSubmit={formik.handleSubmit}
      >
        {/* Campo Nombre */}
        <div className="input-container-objetivo full-width">
          <FontAwesomeIcon className="icon" icon={faUser} />
          <input
            name="objective.name"
            type="text"
            placeholder="Nombre del objetivo"
            value={formik.values.objective.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`input ${formik.touched.objective?.name && formik.errors.objective?.name
                ? "input-error"
                : ""
              }`}
          />
        </div>

        {/* Dirección */}
        <div className="row">
          <div className="input-container-objetivo half-width">
            <FontAwesomeIcon className="icon" icon={faLocationDot} />
            <input
              name="address.street"
              type="text"
              placeholder="Calle"
              value={formik.values.address.street}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`input ${formik.touched.address?.street && formik.errors.address?.street
                  ? "input-error"
                  : ""
                }`}
            />
          </div>
          <div className="input-container-objetivo half-width">
            <FontAwesomeIcon className="icon" icon={faHashtag} />
            <input
              name="address.number"
              type="number"
              placeholder="Número"
              value={formik.values.address.number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`input ${formik.touched.address?.number && formik.errors.address?.number
                  ? "input-error"
                  : ""
                }`}
            />
          </div>
        </div>

        <div className="row">
          <div className="input-container-objetivo half-width">
            <FontAwesomeIcon className="icon" icon={faHouse} />
            <input
              name="address.floor"
              type="number"
              placeholder="Piso"
              value={formik.values.address.floor}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`input ${formik.touched.address?.floor && formik.errors.address?.floor
                  ? "input-error"
                  : ""
                }`}
            />
          </div>
          <div className="input-container-objetivo half-width">
            <FontAwesomeIcon className="icon" icon={faBuilding} />
            <input
              name="address.apartament"
              type="number"
              placeholder="Departamento"
              value={formik.values.address.apartament}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`input ${formik.touched.address?.apartament &&
                  formik.errors.address?.apartament
                  ? "input-error"
                  : ""
                }`}
            />
          </div>
        </div>

        {/* Selects */}
        <select
          name="city.id_district"
          value={formik.values.city.id_district}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`selectObjetivo ${formik.touched.city?.id_district && formik.errors.city?.id_district
              ? "input-error"
              : ""
            }`}
        >
          <option value="">Selecciona un distrito</option>
          {distrito?.map((distrit) => (
            <option key={distrit.id} value={distrit.id}>
              {distrit.name}
            </option>
          ))}
        </select>

        <select
          name="city.name"
          value={formik.values.city.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`selectObjetivo ${formik.touched.city?.name && formik.errors.city?.name
              ? "input-error"
              : ""
            }`}
        >
          <option value="">Selecciona una ciudad</option>
          {ciudad?.map((city) => (
            <option key={city.id} value={city.name}>
              {city.name}
            </option>
          ))}
        </select>

        {/* Botones */}
        <div className="contenedorButtonsGuardarObjetivo">
          <button
            type="button"
            className="cancelButton"
            onClick={() => setMenuVisible(!menuVisible)}
          >
            Cancelar
          </button>
          <button type="submit" className="submitButton">
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
};

export default AgregarObjetivoModal;