import React from "react";
import "../css/CardHome.css";

const CardHome = ({ children, number, msj }) => {
  return (
    <div
      className={
        number !== null && msj ? "contenedorCardHome" : "contenedorCardHome2"
      }
    >
      {children}
      {number !== null ? (
        <div className="contenedorDatosCardHome">
          <p className="NumeroCardHome">{number}</p>
          <p className="textCardHome">{msj}</p>
        </div>
      ) : (
        <div className="contenedorDatosCardHome">
          <div class="loader"></div>
          <p className="textCardHome">{msj}</p>
        </div>
      )}
    </div>
  );
};

export default CardHome;

/* HTML: <div class="loader"></div> */
