import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteRegistry, getRegisterEmployee, postRegistryArray, putRegistry } from "../utils/peticionesApi";
import "../css/RegistroEmpleado.css";
import { NewContext } from "../utils/ContextProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import UpdateRecordModal from "../forms/Registros/UpdateRecordModal";

const RegistroEmpleado = () => {
  const { getAlert, month } = useContext(NewContext);
  const { id, name } = useParams();
  const [registros, setRegistros] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const months = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  useEffect(() => {
    if (id) listarDatos();
  }, []);

  const listarDatos = async () => {
    const monthSelected = months[month];
    try {
      const response = await getRegisterEmployee(id, monthSelected);
      setRegistros(response.data?.content);
    } catch (error) {
      console.log(error);
    }
  };

  const groupedByPlaceAndDate = registros.reduce((acc, item) => {
    const placeId = item.place.id;
    const date = item.date;

    if (!acc[placeId]) {
      acc[placeId] = [];
    }

    if (!acc[placeId][date]) {
      acc[placeId][date] = [];
    }

    acc[placeId][date].push(item);

    return acc;
  }, {});

  const deleteRegister =async(records)=>{
    const dto = []
    records.map(record => dto.push(record.id))
    try {
      const result = await deleteRegistry(dto)
      getAlert({msj: result.data.message})
      listarDatos()
    } catch (error) {
      console.log(error)
    }
  }

  const updateRegistri = async (records)=>{
    setSelectedRecord(records)
  }

  const handleSave = async (updatedRecord, newHorarioCortado, deletePeriodo) => {
    if(deletePeriodo){
      const dtoDelete = []
      const idsDeletes = selectedRecord.records.filter(registro=> registro.shiftId === 2)
      idsDeletes.map(registro => dtoDelete.push(registro.id))
      try {
        await deleteRegistry(dtoDelete)
      } catch (error) {
        console.log(error)
      }
    }
    if(newHorarioCortado.length > 0){
      const newDto = newHorarioCortado.map(horario => (
        { 
        date: updatedRecord[0].date.split('/').join('-'),
        time: Object.keys(horario)[0] === 'newStartHorario' ? `${horario.newStartHorario}:00` : `${horario.newEndHorario}:00`,
        place_id: updatedRecord[0].placeId,
        objective_id: updatedRecord[0].objectiveId,
        shift_id: 2,
        employee_id: updatedRecord[0].employeeId,
        type: Object.keys(horario)[0] === 'newStartHorario' ? 'I' : 'E',
      } 
      ))
      const dto = updatedRecord.map(record => ({
        date: record.date.split('/').join('-'),
        time: record.time.split(':').length === 2 ? record.time + ':00' : record.time,
        place_id: record.placeId,
        objective_id: record.objectiveId,
        shift_id: 1,
        employee_id: record.employeeId,
        type: record.type,
        id: record.id,
      }))
      try {
        await postRegistryArray(newDto)
        console.log(dto)
        const response = await putRegistry(dto);
        console.log(response)
        getAlert({ msj: response.data.message });
        listarDatos(); 
        setSelectedRecord(null); 
      } catch (error) {
        console.log(error)
      }
    }else{
      const dto = updatedRecord.map(record => ({
       date: record.date.split('/').join('-'),
       time: record.time.split(':').length === 2 ? record.time + ':00' : record.time,
       place_id: record.placeId,
       objective_id: record.objectiveId,
       shift_id: record.shiftId,
       employee_id: record.employeeId,
       type: record.type,
       id: record.id,
     }))
     console.log(dto)
     try {
       const response = await putRegistry(dto);
       getAlert({ msj: response.data.message });
       listarDatos(); 
       setSelectedRecord(null); 
     } catch (error) {
       console.log(error);
     } 
    }
  };

  return (
    <div className="contenedorRegistroEmpleado">
      <p className="tituloNombreEmpleado">
        Empleado: <span className="nameRegistro">{name}</span>
      </p>
      <div className="listadoPuestoRegistros">
        {
        groupedByPlaceAndDate &&
        Object.entries(groupedByPlaceAndDate).map(([placeId, dates]) => {
          const placeName = dates[Object.keys(dates)[0]][0].place.name;
          const objetivoName = dates[Object.keys(dates)[0]][0].objective.name;
          
          return (
            <div className="contenedorPuestoRegistro" key={placeId}>
              <h3 className="nombrePuesto">
                Puesto: {objetivoName} {placeName}
              </h3>
              {Object.entries(dates).map(([date, records], index) => (
              dates[Object.keys(dates)[index]].length === 1 && records[0].type === 'E' ? null :        
                <div key={index} className="filaRegistro">
                  <div className="filaRegistroHorario">
                    <p className="textoFecha">{date}</p>
                    {dates[Object.keys(dates)[index]].length === 1 && <p className="aclaracionHorarioNocturno">1</p>}
                    {dates[Object.keys(dates)[index]].length === 3 && dates[Object.keys(dates)[index]][2].type === 'I' && <p className="aclaracionHorarioNocturno">1</p>}
                    <div className="contenedorFilaRegistroHorario">         
                      {records
                        .sort((a, b) => {
                          if (a.shiftId === 4 && b.shiftId === 4) {
                            if (a.type === "I" && b.type === "E") return -1;
                            if (a.type === "E" && b.type === "I") return 1;
                          }
                          if (a.shiftId === 1 && b.shiftId === 1) {
                            if (a.type === "I" && b.type === "E") return -1;
                            if (a.type === "E" && b.type === "I") return 1;
                          }
                          if (a.shiftId === 2 && b.shiftId === 2) {
                            if (a.type === "I" && b.type === "E") return -1;
                            if (a.type === "E" && b.type === "I") return 1;
                          }
                          if (a.shiftId === 4) return -1;
                          if (b.shiftId === 4) return 1;

                          if (a.shiftId === 1 && b.shiftId === 2) return -1;
                          if (a.shiftId === 2 && b.shiftId === 1) return 1;
                          return 0;
                        })
                        .map((record, idx) => (
                            <div key={idx} className="contenedorFilaRegistroHorario"> 
                              
                              {record.shiftId === 2 &&
                              record.type === "I" ? (
                                <FontAwesomeIcon
                                  className="iconHorarioCortado"
                                  icon={faPlus}
                                />
                              ) : null}
                              
                              {
                                dates[Object.keys(dates)[index]].length === 3 && idx === 2 && record.type === "E" ?
                                null :
                                dates[Object.keys(dates)[index]].length === 5 && idx === 4 && record.type === "E" ?
                                null :
                                <p>{`${record.time.split(':')[0]}:${record.time.split(':')[1]} hs`}</p>
                              }
                              {record.type === "I" && (
                                <FontAwesomeIcon icon={faArrowRightLong} />
                              )}
                              
                              {
                                dates[Object.keys(dates)[index]].length === 1 && record.type === 'I' ?
                                <p>{dates[Object.keys(dates)[index]][0].time}</p>
                                : null
                              }
                              {
                                dates[Object.keys(dates)[index]].length === 3 && record.type === 'I' && idx === 2 ?
                                <p>{dates[Object.keys(dates)[index]][0].time}</p>
                                : null
                              }
                            </div>
                        ))}
                    </div>
                  </div>

                  <div className="contenedorButtonsRegistro">

                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      className="buttonEditRegister"
                      onClick={()=> records.length === 1 || records.length === 3 ? updateRegistri({records, date, dates, index}) : updateRegistri({records, date})}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="buttonDeleteRegister"
                      onClick={()=> deleteRegister(records)}
                    />
                  </div>
                </div>
              ))}
            </div>
          );
        })}
      </div>
      {selectedRecord && (
        <UpdateRecordModal
          record={selectedRecord}
          onSave={handleSave}
          onCancel={() => setSelectedRecord(null)}
        />
      )}
    </div>
  );
};

export default RegistroEmpleado;
