import React, { useContext, useEffect, useState } from 'react'
import '../css/ModalAlert.css'
import { NewContext } from '../utils/ContextProvider'

const ModalAlert = () => {
  const {valuesAlert, setValuesAlert, setFlagAlert} = useContext(NewContext)
    const [getTipe, setTipe] = useState(valuesAlert.tipe)
    const [width, setWidth] = useState(100);

    useEffect(()=>{
        setTipe(valuesAlert.tipe)
        const timer = setTimeout(() => {
          setValuesAlert({msj: '', tipe: ''})
          setFlagAlert(false)
        }, 2500);
    
        const widthTimer = setInterval(() => {
          setWidth(prevWidth => prevWidth - 1);
        }, 25);
    
        return () => {
          clearTimeout(timer);
          clearInterval(widthTimer);
        };
    }, [valuesAlert.tipe])

  if(valuesAlert.tipe === '' || valuesAlert.msj === '') return null

  return (
    <div className={`contenedor-modal-alert ${getTipe}`} >
      <div className='contenedor-modal'>
        <h4>{valuesAlert.tipe === 'succes' ? 'Confimado' : valuesAlert.tipe === 'error' ? 'Error' : 'Advertencia'}</h4>
        <p>{valuesAlert.msj}</p>
      </div>
      <div className={getTipe === 'succes' ? 'bar-succes' : getTipe === 'warning' ? 'bar-worning' : 'bar-error'} style={{ width: `${width}%` }}></div>
    </div>
  )
}

export default ModalAlert
