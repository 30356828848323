import React, {useContext, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Navbar from './Navbar';
import TopNavbar from './TopNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { NewContext } from "../utils/ContextProvider";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        marginLeft: "-10%",
        marginRight: "15%",
        marginTop: "8%",
        borderBottom: "20px solid transparent",
        textAlign: "center",
        cursor: 'pointer',
    },
    cardContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    icon: {
        fontSize: "4em",
        marginBottom: "7%",
    },
    fileInput: {
        display: 'none',
    },
});

const Importador = () => {
  const { getToken } = useContext(NewContext)
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState(null);
    const url = 'https://www.softnik.live/upload';
    const classes = useStyles();
    const Swal = require('sweetalert2')
    const closeModal = () => {
        setShowModal(false);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const fileName = selectedFile.name;
        setFile(selectedFile);
      };
    
      const importarEmpleados = async () => {
        if (!file) {
          console.error('Selecciona un archivo antes de importar.');
          return;
        }
    
        try {
          const formData = new FormData();
          formData.append('file', file);
    
          const response = await axios.post(url + '/employee', formData, {
            headers: {
              Authorization: `Bearer ${getToken}`,
              'Content-Type': 'multipart/form-data',
            },
          });
    
          if (response.status === 200) {
            if (response.data.success) {
                closeModal();

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Excel agregado con éxito.',
                        showConfirmButton: false,
                        timer: 1500
                    });
            }
            setError(null);
          } else {
            console.error('Hubo un problema al importar el excel.');
          }
        } catch (error) {
            console.error('Error al importar el excel:', error.response.data.message);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message,
                footer: 'Formato válido: .xlsx'
            });
            setError(error.response.data.message);
        }
      };
      
      const importarAsistencias = async () => {
        if (!file) {
          console.error('Selecciona un archivo antes de importar.');
          return;
        }
    
        try {
          const formData = new FormData();
          formData.append('file', file);
    
          const response = await axios.post(url , formData, {
            headers: {
              Authorization: `Bearer ${getToken}`,
              'Content-Type': 'multipart/form-data',
            },
          });
    
          if (response.status === 200) {
            if (response.data.success) {
                closeModal();

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Excel agregado con éxito.',
                        showConfirmButton: false,
                        timer: 1500
                    });
            }
            setError(null);
          } else {
            console.error('Hubo un problema al importar el excel.');
          }
        } catch (error) {
            console.error('Error al importar el excel:', error.response.data.message);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message,
                footer: 'Formato válido: .xlsx'
            });
            setError(error.response.data.message);
        }
      };

    return (
        <div style={{marginLeft:'20%'}}>
            <Navbar />
            <TopNavbar />
            <div className={classes.cardContainer}>
                {/* Primera tarjeta */}
                <Card className={classes.root}>
                    <CardContent>
                        <FontAwesomeIcon icon={faUsers} className={classes.icon} style={{ color: '#2A9AB6' }} />
                        <Typography gutterBottom variant="h5" component="h2">
                        Empleados
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                        Haz clic aquí para importar empleados de forma masiva.
                        </Typography>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            style={{
                                padding: '5px',
                                fontSize: '12px', 
                            }}
                        />

                        <button
                        className="btn btn-primary"
                        style={{
                            backgroundColor: '#2A9AB6',
                            color: 'white',
                            marginLeft: '10px', // Ajusta el margen izquierdo
                            flex: '0 0 auto', // Para evitar que el botón se extienda más allá del contenido,
                            paddingTop: '5px', 
                        }}
                        onClick={importarEmpleados}
                        >
                        Importar Excel
                        </button>
                    </CardContent>
                    </Card>

                {/* Segunda tarjeta */}
                <Card className={classes.root}>
                    <CardContent>
                        <FontAwesomeIcon icon={faCalendarDays} className={classes.icon} style={{ color: '#2A9AB6' }} />
                        <Typography gutterBottom variant="h5" component="h2">
                            Asistencias
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Aquí se pueden importar registros de asistencia de forma masiva.
                        </Typography>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            style={{
                                padding: '5px',
                                fontSize: '12px', 
                            }}
                        />

                        <button
                        className="btn btn-primary"
                        style={{
                            backgroundColor: '#2A9AB6',
                            color: 'white',
                            marginLeft: '10px', // Ajusta el margen izquierdo
                            flex: '0 0 auto', // Para evitar que el botón se extienda más allá del contenido,
                            paddingTop: '5px', 
                        }}
                        onClick={importarAsistencias}
                        >
                        Importar Excel
                        </button>
                    </CardContent>
                </Card>
            </div>
        </div>
    );    }

    export default Importador;