import React, { useContext, useEffect, useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getPersona, postRequest, putRequest } from '../../utils/peticionesApi';
import { NewContext } from '../../utils/ContextProvider';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '70vw',
    margin: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    width: '100%',
  },
  dateField: {
    margin: theme.spacing(1),
    width: '100%',
  },
  observationsField: {
    margin: theme.spacing(1),
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  cancelButton: {
    width: 192,
    backgroundColor: '#FFFFFF',
    border: '1px solid #2A9AB6',
    color: '#2A9AB6',
    '&:hover': {
      backgroundColor: '#e6f7fc',
    },
  },
  submitButton: {
    width: 300,
    backgroundColor: '#8F3B8C',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#7b336e',
    },
  },
}));

const AgregarSolicitudModal = ({
  reasons,
  rol,
  closeModalSolicitud,
  solicitud,
  handleSubmitSolicitud,
}) => {
  const classes = useStyles();
  const { getAlert, getId, selectedEmployee } = useContext(NewContext);
  const [request_since, setStartDate] = useState('');
  const [request_expiration, setEndDate] = useState('');
  const [observations_request, setObservations] = useState('');
  const [id_reason, setSelectedMotivo] = useState('');
  const [selectedEmpleadoId, setSelectedEmpleadoId] = useState('');
  const [empleados, setEmpleados] = useState([]);
  const [nombre, setNombre] = useState('');
  const [isEditing, setIsEditing] = useState(true); // Establecer en true por defecto
  const [active, setActive] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH') {
      listarEmpleados();
    } else {
      setSelectedEmpleadoId(getId);
    }
    if (solicitud) {
      setStartDate(formatDateForInput(solicitud.request_since));
      setEndDate(formatDateForInput(solicitud.request_expiration));
      setObservations(solicitud.observations_request);
      setSelectedMotivo(solicitud.id_reason);
      setSelectedEmpleadoId(solicitud.uuid_employee);
      setNombre({
        name: solicitud.employee_first_name,
        lastname: solicitud.employee_last_name,
        id: solicitud.uuid_employee,
      });
      setActive(solicitud.active);
      setIsEditing(true); // Cambiar a true para habilitar los campos
    } else {
      setStartDate('');
      setEndDate('');
      setObservations('');
      setSelectedMotivo('');
      setNombre('');
      setSelectedEmpleadoId(rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH' ? '' : getId);
      setIsEditing(true);
    }
  }, [solicitud]);

  const formatDateForInput = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) {
      return '';
    }
    const [year, month, day] = dateArray;
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  const handleMotivoChange = (event) => {
    setSelectedMotivo(event.target.value);
  };

  const handleEstadoChange = (event) => {
    setActive(event.target.value);
  };

  const handleEmpleadoChange = (event) => {
    const empleadoSeleccionado = empleados.find((empleado) => empleado.id === event.target.value);
    if (empleadoSeleccionado) {
      const { person, id } = empleadoSeleccionado;
      setNombre({ name: person.name, lastname: person.lastname, id });
      setSelectedEmpleadoId(event.target.value);
    }
  };

  const listarEmpleados = async () => {
    try {
      const response = await getPersona();
      setEmpleados(response.data.content);
    } catch (error) {
      console.error('Error al listar empleados:', error);
    }
  };

  const onSubmit = async () => {
    const formDataToSendSolicitud = {
      active,
      employee_first_name: nombre?.name,
      employee_last_name: nombre?.lastname,
      id_reason: id_reason,
      id_request: solicitud ? solicitud.id_request : 0,
      observations_request: observations_request,
      request_expiration: request_expiration,
      request_since: request_since,
      uuid_employee: nombre?.id,
    };

    try {
      if (!solicitud) {
        const response = await postRequest(formDataToSendSolicitud);
        console.log('Solicitud agregada correctamente:', response);
      } else {
        const response = await putRequest(formDataToSendSolicitud);
        console.log('Editando solicitud existente:', formDataToSendSolicitud);
      }

      handleSubmitSolicitud(formDataToSendSolicitud);
      closeModalSolicitud();
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH' ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="empleado-label">Empleados</InputLabel>
                <Select
                  labelId="empleado-label"
                  id="empleados"
                  value={selectedEmpleadoId}
                  onChange={handleEmpleadoChange}
                  disabled={!!solicitud}
                  label="Empleados"
                >
                  {empleados &&
                    empleados.map((empleado) => (
                      <MenuItem key={empleado.id} value={empleado.id}>
                        {empleado.person.name} {empleado.person.lastname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : (
              <div>
                <InputLabel id="solicitante-label">Solicitante</InputLabel>
                <p>
                  {selectedEmployee?.person.name} {selectedEmployee?.person.lastname}
                </p>
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="startDate"
              label="Fecha de inicio"
              type="date"
              value={request_since}
              onChange={(event) => setStartDate(event.target.value)}
              className={classes.dateField}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!isEditing}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id="finishDate"
              label="Fecha de fin"
              type="date"
              value={request_expiration}
              onChange={(event) => setEndDate(event.target.value)}
              className={classes.dateField}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={!isEditing}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="motivo-label">Motivos</InputLabel>
              <Select
                labelId="motivo-label"
                id="motivo"
                value={id_reason}
                onChange={handleMotivoChange}
                disabled={!isEditing}
                label="Motivos"
              >
                {reasons &&
                  reasons.map((reason) => (
                    <MenuItem key={reason.id} value={reason.id}>
                      {reason.reason}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="estado-label">Estado</InputLabel>
              <Select
                labelId="estado-label"
                id="estado"
                value={active}
                onChange={handleEstadoChange}
                disabled={!isEditing}
                label="Estado"
              >
                <MenuItem value={true}>Aprobado</MenuItem>
                <MenuItem value={false}>Pendiente</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="observations"
              label="Observaciones"
              multiline
              minRows={4}
              value={observations_request}
              onChange={(event) => setObservations(event.target.value)}
              className={classes.observationsField}
              disabled={!isEditing}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.buttonContainer}>
        <Button className={classes.cancelButton} onClick={closeModalSolicitud}>
          Cancelar
        </Button>
        {solicitud && (
          <Button className={classes.submitButton} onClick={onSubmit}>
            Guardar cambios
          </Button>
        )}
        {!solicitud && (
          <Button className={classes.submitButton} onClick={onSubmit}>
            Agregar solicitud
          </Button>
        )}
      </div>
    </>
  );
};

export default AgregarSolicitudModal;