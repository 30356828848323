import { useEffect, useState, useContext } from 'react';
import { getFolderContents, downloadFile, postFolders, deleteFolder } from '../utils/peticionesApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile, faCircleLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import OptionComponentDocuments from "../forms/Documentos/OptionComponentDocuments";
import '../css/Documentos.css';
import Button from "../components/Button";
import { NewContext } from "../utils/ContextProvider";

const Documentos = () => {
    const [contenidos, setContenidos] = useState([]);
    const [currentFolder, setCurrentFolder] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [rutaActual, setRutaActual] = useState([]);
    const [modalViewExport, setModalViewExport] = useState(false);
    const [modalView, setModalView] = useState(false);
    const { getAlert, getUUID } = useContext(NewContext);
    const [modalCreateFolder, setModalCreateFolder] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [showOptions, setShowOptions] = useState(null);
    const [file, setFile] = useState(null);

    const esArchivo = (nombre) => {
        const extensionesArchivos = ['.xls', '.xlsx', '.txt', '.pdf', '.doc', '.docx', '.jpg', '.png'];
        return extensionesArchivos.some(ext => nombre.toLowerCase().endsWith(ext));
    };

    useEffect(() => {
        if (!modalViewExport) {
            setSelectedItems([]);
        }
    }, [modalViewExport]);

    const listarContenidos = async (folder) => {
        try {
            const folderPath = folder || '';
            const resp = await getFolderContents(folderPath);
            if (Array.isArray(resp)) {
                setContenidos(resp);
            } else {
                console.error('Respuesta inesperada:', resp);
                setContenidos([]);
            }
        } catch (error) {
            console.error('Error al listar carpetas:', error);
            setContenidos([]);
        }
    };

    useEffect(() => {
        listarContenidos('');
    }, []);

    const abrirCarpeta = (folderName) => {
        const nuevaCarpeta = currentFolder ? `${currentFolder}/${folderName}` : folderName;
        setRutaActual([...rutaActual, folderName]);
        setCurrentFolder(nuevaCarpeta);
        listarContenidos(nuevaCarpeta);
    };

    const descargarArchivo = (fileName) => {
        const fullFilePath = currentFolder ? `${currentFolder}/${fileName}` : fileName;
        downloadFile(fullFilePath);
    };

    const handleSelectItem = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter(i => i !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    const descargarSeleccionados = () => {
        if (selectedItems.length === 0) {
            getAlert({ msj: 'No has seleccionado ningún archivo o carpeta', tipe: 'warning' });
            return;
        }
        selectedItems.forEach(item => {
            descargarArchivo(item.name);
        });
        setSelectedItems([]);
    };

    const contenidosFiltrados = Array.isArray(contenidos)
        ? contenidos.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
        : [];

    const carpetas = contenidosFiltrados.filter(item => !esArchivo(item.name));
    const archivos = contenidosFiltrados.filter(item => esArchivo(item.name));

    const volverAtras = () => {
        if (rutaActual.length > 0) {
            const nuevaRuta = [...rutaActual];
            nuevaRuta.pop();
            const nuevaCarpeta = nuevaRuta.join('/') || '';
            setRutaActual(nuevaRuta);
            setCurrentFolder(nuevaCarpeta);
            listarContenidos(nuevaCarpeta);
        }
    };

    const handleCreateFolder = async () => {
        try {
            if (newFolderName) {
                const response = await postFolders(newFolderName);

                if (response.status === 200) {
                    getAlert({ msj: "Carpeta creada con éxito.", tipe: 'succes' });
                    setNewFolderName('');
                    setModalCreateFolder(false);
                    listarContenidos(currentFolder);
                } else {
                    getAlert({ msj: response.data.message, tipe: 'error' });
                }
            } else {
                getAlert({ msj: "El nombre de la carpeta no puede estar vacío.", tipe: 'warning' });
            }
        } catch (error) {
            console.error('Error al crear la carpeta:', error);
            getAlert({ msj: "Error al crear la carpeta.", tipe: 'error' });
        }
    };

    const eliminarCarpeta = async (folderName) => {
        try {
            const response = await deleteFolder(folderName, getUUID);
            if (response.status === 200) {
                getAlert({ msj: 'Carpeta eliminada con éxito.', tipe: 'success' });
                listarContenidos(currentFolder);
            } else {
                getAlert({ msj: response.data.message, tipe: 'error' });
            }
        } catch (error) {
            console.error('Error al eliminar la carpeta:', error);
            getAlert({ msj: 'Error al eliminar la carpeta.', tipe: 'error' });
        }
    };

    const toggleOptions = (index) => {
        setShowOptions(showOptions === index ? null : index);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    return (
        <div className="contenedor-documentos">
            <div>
                <p className="titulo">Mis documentos</p>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {rutaActual.length > 0 && (
                        <button onClick={() => volverAtras()} className="buttonVolverObj">
                            <FontAwesomeIcon icon={faCircleLeft} /> Volver
                        </button>
                    )}
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="searchbar-input-wrap">
                        <OptionComponentDocuments
                            type="text"
                            placeHolder="Buscar por nombre, archivo"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            value={searchTerm}
                            modalView={modalView}
                            setModalView={setModalView}
                            modalViewExport={modalViewExport}
                            setModalViewExport={setModalViewExport}
                            setModalCreateFolder={setModalCreateFolder}
                            handleFileChange={handleFileChange}
                        />
                        {modalCreateFolder && (
                            <div className="modalCreateFolder">
                                <button className="closeButton" onClick={() => setModalCreateFolder(false)}>
                                    &times;
                                </button>
                                <div className="contenedorCrearCarpeta">
                                    <p>Crear nueva carpeta</p>
                                    <input
                                        type="text"
                                        value={newFolderName}
                                        onChange={(e) => setNewFolderName(e.target.value)}
                                        placeholder="Nombre de la carpeta"
                                        className="inputCreateFolder"
                                    />
                                    <div className="button-container">
                                        <button
                                            className="button button-confirm"
                                            onClick={handleCreateFolder}
                                        >
                                            Confirmar
                                        </button>
                                        <button
                                            className="button button-cancel"
                                            onClick={() => setModalCreateFolder(false)}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {
                            modalViewExport && (
                                <div className="modalExport">
                                    <p>Selecciona los archivos y carpetas que deseas descargar:</p>
                                    <Button onClick={descargarSeleccionados} msj='Descargar seleccionados' tipe='violeta' />
                                    <Button onClick={() => setModalViewExport(false)} msj='Cerrar' tipe='gris' />
                                </div>
                            )
                        }

                    </div>
                </div>
                <div className="carpetas-container-general">
                    <h3>Carpetas</h3>
                </div>
                <div className="carpetas-container">
                    {carpetas.length > 0 ? (
                        carpetas.map((carpeta, index) => (
                            <div key={index} className="carpeta-card">
                                <div className="carpeta-icono" onClick={() => abrirCarpeta(carpeta.name)}>
                                    <FontAwesomeIcon icon={faFolder} size="3x" />
                                </div>
                                <p className="nombre-carpeta">{carpeta.name}</p>
                                <FontAwesomeIcon icon={faEllipsisV} onClick={() => toggleOptions(index)} />
                                {showOptions === index && (
                                    <div className="carpeta-opciones">
                                        <p onClick={() => console.log('Editar')}>Editar</p>
                                        <p onClick={() => eliminarCarpeta(carpeta.name)}>Eliminar</p>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No se encontraron carpetas</p>
                    )}
                </div>

                <div className="archivos-container">
                    <h3>Archivos</h3>
                    {archivos.length > 0 ? (
                        archivos.map((archivo, index) => (
                            <div key={index} className="archivo-card" onClick={() => descargarArchivo(archivo.name)}>
                                <div className="archivo-icono">
                                    <FontAwesomeIcon icon={faFile} size="3x" />
                                </div>
                                <p className="nombre-archivo">{archivo.name}</p>
                            </div>
                        ))
                    ) : (
                        <p>No se encontraron archivos</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Documentos;