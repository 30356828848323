import React from 'react';
import '../css/OptionComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faXmark, faUpload, faPlus } from '@fortawesome/free-solid-svg-icons';

const OptionComponenteEmpleado = ({
  value,
  onChange,
  setModalView,
  agregarFunction,
  modalView,
  setModalViewExport,
  modalViewExport,
  upload,
}) => {
  return (
    <div className="contenedorOptions">
      <input
        className="inputComponent"
        type="text"
        placeholder="Buscar por nombre, apellido o DNI"
        value={value}
        onChange={onChange}
      />
      <div>
        <button className="button-icons-options" onClick={agregarFunction}>
          <FontAwesomeIcon icon={!modalView ? faPlus : faXmark} className="icon-options" />
        </button>
        <button
          className="button-icons-options"
          onClick={upload}
        >
          <FontAwesomeIcon icon={!modalViewExport ? faUpload : faXmark} className="icon-options" />
        </button>
      </div>
    </div>
  );
};

export default OptionComponenteEmpleado;
