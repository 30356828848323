import React, { useMemo, useState } from 'react';
import { GoogleMap, LoadScript, useLoadScript, Marker } from '@react-google-maps/api';
import {
	Combobox,
	ComboboxInput,
	ComboboxPopover,
	ComboboxList,
	ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import '../css/map.css'

const libraries = ["places"];

export default function Places({selected, setSelected, center}){
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: "AIzaSyB-k7ovzwVnh5zBlnmVgqJ6-F6pq3JRg9Y",
    libraries
  })
  
  if(!isLoaded) return <div>Loading...</div>

  return <Map selected={selected} setSelected={setSelected}/>
}



const Map = ({selected, setSelected}) => {

  return (
    <div className='contenedorMapa'>
      <div className="contenedorInputMap">
        <PlacesAutocomplete setSelected={setSelected} />
      </div>

      <GoogleMap
        zoom={15}
        center={selected}
        mapContainerClassName="map-container"
        onClick={(e) => {
          const { latLng } = e;
          const lat = latLng.lat();
          const lng = latLng.lng();
          setSelected({ lat, lng });
        }}
      >
        {selected && <Marker position={selected} />}
      </GoogleMap>
    </div>
  );
}

const PlacesAutocomplete =({setSelected})=>{

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const handleSelect = async (address) => {
    console.log('llego')
    setValue(address, false);
    clearSuggestions();

    getGeocode({ address: address }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      setSelected({ lat, lng });
    });
    
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="inputMap"
        placeholder="Buscar direccion"
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption className='itemBusquedaMap' key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}


