import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import '../css/CurrenPagination.css'

const CustomPagination = ({ currentPage, totalPages, setCurrentPage }) => {
  if (totalPages === 0) return null;

  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === totalPages - 1;

  return (
    <div className='contenedorPagination'>
      <p>{currentPage + 1} de</p>
      <p>{totalPages}</p>

      <button
        className={`${isFirstPage ? 'buttonGrisPagination' : 'buttonAzulPagination'} buttonPagination`}
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={isFirstPage}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>

      <button
        className={`${isLastPage ? 'buttonGrisPagination' : 'buttonAzulPagination'} buttonPagination`}
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={isLastPage}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    </div>
  );
};

export default CustomPagination;
