import React, { useState } from 'react';
import '../css/Calendario.css'

const getDaysInMonth = (year, month) => {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

const Calendar = ({selectedDays, setSelectedDays}) => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
 
  const daysInMonth = getDaysInMonth(currentYear, currentMonth);
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
  const hoy = new Date()
  const newHoy = `${hoy.getFullYear()}-${(hoy.getMonth() + 1) < 10 ? '0' + (hoy.getMonth() + 1) : (hoy.getMonth() + 1)}-${hoy.getDate()}`
 
console.log(newHoy)
  const handleDayClick = (day) => {
    const dayStr = day.toISOString().split('T')[0];
    
    setSelectedDays((prevSelectedDays) => {
      const exists = prevSelectedDays.find(d => d.date === dayStr);
      if (exists) {
        return prevSelectedDays.filter(d => d.date !== dayStr);
      } else {
        return [...prevSelectedDays, { date: dayStr, startTime: '', endTime: '' }];
      }
    });
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth + 1) % 12);
    if (currentMonth === 11) {
      setCurrentYear((prevYear) => prevYear + 1);
    }
  };

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth - 1 + 12) % 12);
    if (currentMonth === 0) {
      setCurrentYear((prevYear) => prevYear - 1);
    }
  };

  const renderCalendar = () => {
    const weeks = [];
    let days = Array(firstDayOfMonth).fill(null).concat(daysInMonth);

    while (days.length > 0) {
      weeks.push(days.splice(0, 7));
    }

    return weeks.map((week, index) => (
      <tr key={index}>
        {week.map((day, idx) => {
        
          const dayStr = day ? day.toISOString().split('T')[0] : '';
          
      
          const isSelected = selectedDays.find(selectedDay => selectedDay.date === dayStr);
          
          
          return(
          <td
            key={idx}
            onClick={() => day && handleDayClick(day)}
            style={{
              backgroundColor: isSelected ? '#3F63AC' : 'white',
              cursor: day ? 'pointer' : 'default',
              textAlign: 'center',
              color: isSelected ? '#fff' : dayStr === newHoy? '#3F63AC' : '#000',
              borderRadius: '0.5rem',
              textDecoration: dayStr === newHoy ? 'underline' : ''
              
            }}
          >
            {day ? day.getDate() : ''}
          </td>
        )})}
      </tr>
    ));
  };

  return (
    <div className='contenedorTableCalendario'>
      <div className='contenedorButtonsCalendario'>
        <button onClick={handlePrevMonth}>Mes Anterior</button>
        <p>{`${currentYear}-${currentMonth + 1}`}</p>
        <button onClick={handleNextMonth}>Mes Proximo</button>
      </div>
      <table className='tableCalendario'>
        <thead>
          <tr>
            {['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'].map((day, index) => (
              <th key={index}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>{renderCalendar()}</tbody>
      </table>
    </div>
  );
};

export default Calendar;

