import axios from "axios";

import {
  URL_ASISTENCIA,
  URL_CITY,
  URL_DISTRICT,
  URL_DOCUMENTACION,
  URL_EMPLOYEE,
  URL_EXPORT_WITH_DETAIL,
  URL_EXPORT_WITHOUT_DETAIL,
  URL_OBJETIVOS,
  URL_PICTURE,
  URL_REASON,
  URL_REGISTERS,
  URL_REGSITRY,
  URL_REGISTRY_IMPORT,
  URL_EMPLOYEE_IMPORT,
  URL_REQUEST,
  URL_USER,
  URL_PLACE,
  URL_REGISTER,
  URL_PERIOD,
  URL_REGISTRYARRAY,
  URL_REGISTRYDELETE,
  URL_REGISTRYUPDATE,
  URL_VISIT,
  URL_STADISTICS,
  URL_STATUS,
  URL_STADISTICSARRIVAL,
  URL_BIRDAYS,
  URL_TRANSACTION,
  URL_TRANSACTION_REPORT,
  URL_FOLDER,
  URL_SUSPENSION,
  URL_DEPARTAMENT
} from "./urls";
import { json } from "react-router-dom";

const postUserLogin = ({ username, password }) => {
  return axios.post(
    URL_USER + "login",
    { username, password },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
    }
  );
};

const getRegisters = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return axios.get(URL_REGISTERS, {
    params: {
      page: 0,
      size: 500,
      timeZone: timeZone,
    }
  });
};


const getRegister = () => {
  return axios.get(URL_REGISTER, {
    params: {
      page: 0,
      size: 500,
    },
  })
}

const getRegisterMonth = (month, year) => {
  return axios.get(`${URL_REGISTER}/monthly/${month}/${year}`);
}

const getRegisterEmployee = (EId, month) => {
  return axios.get(`${URL_REGISTER}/user/${EId}/monthly/${month}`, {
    params: {
      page: 0,
      size: 500,
    },
  });
}

const getUUID = (email) => {
  return axios.get(URL_EMPLOYEE + "/search/" + email, {
    params: {
      email: email,
    },
  });
};

const getDateUser = (username) => {
  return axios.get(URL_USER + username, {
    withCredentials: true,
  });
};

const postAsistencia = (dto) => {
  return axios(URL_ASISTENCIA, {
    data: JSON.stringify(dto),
    method: 'POST'
  });
};

const getImportEmployees = (file) => {
  const axiosInstance = axios.create();
  const formData = new FormData();
  formData.append("file", file);
  const token = sessionStorage.getItem("access_token");
  return axiosInstance.post(URL_EMPLOYEE_IMPORT, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => {
      console.log("Respuesta del servidor:", response.data);
    })
    .catch(error => {
      if (error.response) {
        console.error("Código de estado:", error.response.status);
        console.error("Datos de la respuesta:", error.response.data);
      } else if (error.request) {
        console.error("No se recibió respuesta del servidor:", error.request);
      } else {
        console.error("Error al configurar la solicitud:", error.message);
      }
    });
};

const getImportAsistent = (file) => {
  const axiosInstance = axios.create();
  const formData = new FormData();
  formData.append("file", file);
  const token = sessionStorage.getItem("access_token");
  return axiosInstance.post(URL_REGISTRY_IMPORT, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => {
      console.log("Respuesta del servidor:", response.data);
    })
    .catch(error => {
      if (error.response) {
        console.error("Código de estado:", error.response.status);
        console.error("Datos de la respuesta:", error.response.data);
      } else if (error.request) {
        console.error("No se recibió respuesta del servidor:", error.request);
      } else {
        console.error("Error al configurar la solicitud:", error.message);
      }
    });
};

const getAsistencia = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  employeesPerPage
) => {
  return axios.get(URL_REGSITRY, {
    params: {
      fromDate: formattedDateInicio,
      toDate: formattedDateFin,
      page: currentPage,
      size: employeesPerPage,
    },
  });
};

const getListPersona = (id) => {
  return axios.get(URL_EMPLOYEE + "/" + id + "/profile", {
    params: {
      id: id,
    },
  });
};

const getPersonaId = (id) => {
  return axios.get(URL_EMPLOYEE + "/" + id);
}

const getDocumentacion = (id) => {
  return axios.get(URL_DOCUMENTACION + '/' + id)
}

const getReportes = (id, fromDate, toDate) => {
  return axios.get(URL_STADISTICS, {
    params: {
      id: id,
      fromDate: fromDate,
      toDate: toDate,
    }
  })
}

const getStadisctic = (company, departments) => {
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1); // Primer día del mes
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Último día del mes

  // Formato para "fromDate" y "toDate"
  const formattedFromDate = `${firstDay.getDate().toString().padStart(2, '0')}/${(firstDay.getMonth() + 1).toString().padStart(2, '0')}/${firstDay.getFullYear()}`;
  const formattedToDate = `${lastDay.getDate().toString().padStart(2, '0')}/${(lastDay.getMonth() + 1).toString().padStart(2, '0')}/${lastDay.getFullYear()}`;

  // Formato para "startDate" y "endDate"
  const formattedDateInicio = `${firstDay.getFullYear()}-${(firstDay.getMonth() + 1).toString().padStart(2, '0')}-${firstDay.getDate().toString().padStart(2, '0')}`;
  const formattedDateFin = `${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')}`;

  // Llamada al API
  return axios.get(URL_STADISTICS + '/General', {
    params: {
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      company: company,
      startDate: formattedDateInicio,
      endDate: formattedDateFin,
      page: "0",
      pageSize: "3000",
      departments: departments,
    }
  });
};

const getStadiscticArrival = () => {
  return axios.get(URL_STADISTICSARRIVAL)
}

const getStatus = (
  company,
  departments
) => {

  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  const formattedDate = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`;
  const formattedDateInicio = `${year}-${month}-${day}`;
  const formattedDateFin = `${year}-${month}-${day}`;

  return axios.get(URL_STATUS, {
    params: {
      date: formattedDate,
      company: company,
      startDate: formattedDateInicio,
      endDate: formattedDateFin,
      page: "0",
      pageSize: "3000",
      departments: departments,
    }
  })
}

const getBirdays = () => {
  return axios.get(URL_BIRDAYS)
}

const getDistrito = (currentPage = 0) => {
  return axios.get(URL_DISTRICT, {
    params: {
      page: currentPage,
      size: 200,
    },
  });
};

const getCity = (currentPage = 0) => {
  return axios.get(URL_CITY, {
    params: {
      page: currentPage,
      size: 100,
    },
  });
};

const getPersona = (currentPage = 0, employeesPerPage = 2000) => {
  return axios.get(URL_EMPLOYEE, {
    params: {
      page: currentPage,
      size: employeesPerPage,
    },
  });
};

const postPicture = (formData, company, id) => {
  return axios(URL_PICTURE + 'uploadProfile', formData, {
    params: {
      company: company,
      uuid: id
    },
    method: 'POST'
  })
}

const postUser = (formDataUser) => {
  return axios(URL_USER + "register", {
    data: JSON.stringify(formDataUser),
    method: 'POST'
  });
};

const postEmpleado = (dto) => {
  return axios(URL_EMPLOYEE, {
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const deleteEmployee = (id) => {
  return axios.delete(URL_EMPLOYEE + '/' + id)
}

const getObjetivos = () => {
  return axios.get(URL_OBJETIVOS, {
    params: {
      page: 0,
      size: 200,
    },
  })
}

const getObjetivo = (idO) => {
  return axios.get(URL_OBJETIVOS + '/' + idO)
}

const getPuesto = (idObjetivo) => {
  return axios.get(`${URL_PLACE}/${idObjetivo}/places`, {
    params: {
      id: idObjetivo,
    },
  })
}

const postObjetivo = (dto) => {
  return axios(URL_OBJETIVOS, {
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const putObjetivo = (dto) => {
  return axios(URL_OBJETIVOS, {
    data: JSON.stringify(dto),
    method: 'put'
  })
}

const deleteObjetivo = (idObjetivo) => {
  return axios(URL_OBJETIVOS, {
    method: 'DELETE',
    params: {
      id: idObjetivo
    }
  })
}

const postPuesto = (dto) => {
  return axios(URL_PLACE, {
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const putPuesto = (dto) => {
  return axios(URL_PLACE, {
    data: JSON.stringify(dto),
    method: 'put'
  })
}

const getRequest = () => {
  return axios.get(URL_REQUEST)
}

const getRequestById = (uuid) => {
  return axios.get(`${URL_REQUEST}/uuid/${uuid}`);
};

const getReason = () => {
  return axios.get(URL_REASON)
}

const postRequest = (dto) => {
  return axios(URL_REQUEST, {
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const getMiPerfil = (idUser) => {
  return axios.get(URL_EMPLOYEE + '/' + idUser + '/profile', {
    params: {
      id: idUser
    }
  })
}

const putMiPerfil = (id, data) => {
  console.log(data);
  return axios(URL_EMPLOYEE + '/' + id + '/profile', {
    data: data,
    method: 'put'
  })
}

const putPerfil = (id, data) => {
  return axios(URL_EMPLOYEE + '/' + id, {
    data: data,
    method: 'put'
  })
}

const putUsuario = (id, data) => {
  return axios(URL_USER + '/' + id, {
    data: data,
    method: 'put'
  })
}

const getExportWithDetail = (company, desde, hasta, uuid) => {
  const url = uuid ? `${URL_EXPORT_WITH_DETAIL}s/${uuid}` : URL_EXPORT_WITH_DETAIL
  return axios.get(url, {
    params: {
      company,
      fromDate: desde,
      toDate: hasta
    },
    responseType: 'arraybuffer'
  })
}

const getExportWithOutDetail = (company, desde, hasta, uuid) => {
  const url = uuid ? `${URL_EXPORT_WITHOUT_DETAIL}/${uuid}` : URL_EXPORT_WITHOUT_DETAIL
  return axios.get(url, {
    params: {
      company,
      fromDate: desde,
      toDate: hasta
    },
    responseType: 'arraybuffer'
  })
}

const getExportEmployee = (uuid, name, lastName) => {
  if (!uuid) {
    throw new Error('UUID is required to export employee PDF');
  }

  if (!name || !lastName) {
    throw new Error('Name and Last Name are required to generate the file name');
  }

  const url = `${URL_EMPLOYEE}/${uuid}/export-pdf`;

  return axios.get(url, {
    responseType: 'arraybuffer'
  })
    .then(response => {
      const formattedName = `${name}${lastName}`.replace(/\s+/g, '');
      const filename = `${formattedName}.pdf`;

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(link.href);
    })
    .catch(error => {
      console.error('Error exporting employee PDF:', error);
      throw error;
    });
};


const getExportRequest = (id) => {
  if (!id) {
    throw new Error('UUID is required to export the PDF');
  }

  const url = `${URL_REQUEST}/${id}/export-pdf`;

  return axios.get(url, {
    responseType: 'arraybuffer',
  })
    .then(response => {
      if (response.status === 200) {
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'downloaded_file.pdf';

        if (contentDisposition && contentDisposition.includes('filename')) {
          filename = contentDisposition
            .split('filename=')[1]
            .split(';')[0]
            .replace(/['"]/g, '');
        }

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        const objectUrl = window.URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = filename;
        link.click();

        window.URL.revokeObjectURL(objectUrl);
      }
    })
    .catch(error => {
      console.error('Error exporting employee PDF:', error);
      throw error;
    });
};

const getExportAllFirstLastReport = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  employeesPerPage,
  company,
  departments,
  detail) => {

  return axios.get(URL_TRANSACTION_REPORT + 'FirstLastReportFile', {
    params: {
      company: company,
      startDate: formattedDateInicio,
      endDate: formattedDateFin,
      page: currentPage,
      pageSize: employeesPerPage,
      departments: departments,
      exportHeaders: "emp_code,first_name,last_name,dept_name,weekday,att_date,first_punch,last_punch,total_time",
      exportType: detail,
      employees: "-1"
    },
    responseType: 'arraybuffer'
  })
}

const getExportAllScheduledLogReport = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  employeesPerPage,
  company,
  departments,
  detail) => {

  return axios.get(URL_TRANSACTION_REPORT + 'ScheduledLogReportFile', {
    params: {
      company: company,
      startDate: formattedDateInicio,
      endDate: formattedDateFin,
      page: currentPage,
      pageSize: employeesPerPage,
      departments: departments,
      exportHeaders: "emp_code,first_name,last_name,dept_name,att_date,weekday,punch_time,correct_state",
      exportType: detail,
      employees: "-1"
    },
    responseType: 'arraybuffer'
  })
}

const getExportTotalTimeCardReport = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  employeesPerPage,
  company,
  departments,
  detail) => {

  return axios.get(URL_TRANSACTION_REPORT + 'TotalTimeCardReportFile', {
    params: {
      company: company,
      startDate: formattedDateInicio,
      endDate: formattedDateFin,
      page: currentPage,
      pageSize: employeesPerPage,
      departments: departments,
      exportHeaders: "emp_code,first_name,last_name,dept_name,att_date,check_in,check_out,total_time,clock_in,clock_out,total_worked,absent",
      exportType: detail,
      employees: "-1"
    },
    responseType: 'arraybuffer'
  })
}

const getExportEmpSummaryReportFile = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  employeesPerPage,
  company,
  departments,
  detail) => {

  return axios.get(URL_TRANSACTION_REPORT + 'EmpSummaryReportFile', {
    params: {
      company: company,
      startDate: formattedDateInicio,
      endDate: formattedDateFin,
      page: currentPage,
      pageSize: employeesPerPage,
      departments: departments,
      exportHeaders: "emp_code,first_name,last_name,dept_name, total_leave,total_worked,total_late,total_early_leave,total_absent",
      exportType: detail,
      employees: "-1"
    },
    responseType: 'arraybuffer'
  })
}


const getPlaceObject = (id) => {
  return axios.get(URL_PLACE + '/' + id + '/places')
}

const deletePlaceObject = (id) => {
  return axios.delete(URL_PLACE + '/' + id)
}

const deleteRequestObject = (id) => {
  return axios.delete(URL_REQUEST + '/' + id)
}

const postPeriod = (dto) => {
  return axios(URL_PERIOD, {
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const getPeriod = () => {
  return axios.get(URL_PERIOD)
}

/*const getTransaction = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  employeesPerPage,
  company,
) => {
  return axios.get(URL_TRANSACTION, {
    params: {
      company: company,
      startTime: formattedDateInicio,
      endTime: formattedDateFin,
      page: currentPage,
      pageSize: employeesPerPage,
    },
  });
};
*/

const getAllScheduledLogReport = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  pageSize,
  company,
  departments,
  order,
  field
) => {
  const params = {
    company: company,
    startDate: formattedDateInicio,
    endDate: formattedDateFin,
    page: currentPage,
    pageSize: pageSize,
    departments: departments,
    order: order,
    field,
  };
  return axios.get(URL_TRANSACTION_REPORT + 'ScheduledLogReport', { params });
};

const getAllFirstLastReport = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  pageSize,
  company,
  departments
) => {
  const params = {
    company: company,
    startDate: formattedDateInicio,
    endDate: formattedDateFin,
    page: currentPage,
    pageSize: pageSize,
    departments: departments,
  };
  return axios.get(URL_TRANSACTION_REPORT + 'FirstLastReport', { params });
};

const getAllDepartment = (
  search
) => {
  const params = {
    search: search,
  };
  return axios.get(URL_DEPARTAMENT + 'departaments', { params });
};


const getAllTotalTimeCardReport = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  employeesPerPage,
  company,
  departments
) => {
  const params = {
    company: company,
    startDate: formattedDateInicio,
    endDate: formattedDateFin,
    page: currentPage,
    pageSize: employeesPerPage,
    departments: departments,
  };
  return axios.get(URL_TRANSACTION_REPORT + 'TotalTimeCardReport', { params });
};


const getAllEmpSummaryReport = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  pageSize,
  company,
  departments
) => {
  const params = {
    company: company,
    startDate: formattedDateInicio,
    endDate: formattedDateFin,
    page: currentPage,
    pageSize: pageSize,
    departments: departments,
  };
  return axios.get(URL_TRANSACTION_REPORT + 'EmpSummaryReport', { params });
};


const getTransaction = (
  formattedDateInicio,
  formattedDateFin,
  currentPage,
  employeesPerPage,
  company,
  terminalAlias = null // Parámetro opcional para terminal_alias
) => {
  const params = {
    company: company,
    startTime: formattedDateInicio,
    endTime: formattedDateFin,
    page: currentPage,
    pageSize: employeesPerPage,
  };

  // Agregar terminal_alias si está definido
  if (terminalAlias) {
    params.terminalAlias = terminalAlias;
  }

  return axios.get(URL_TRANSACTION, { params });
};

const getFolders = () => {
  return axios.get(URL_FOLDER + '/' + 'list-all')
}

const postRegistryArray = (dto) => {
  return axios(URL_REGISTRYARRAY, {
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const deleteRegistry = (dto) => {
  return axios.delete(URL_REGISTRYDELETE, {
    data: JSON.stringify(dto)
  })
}

const putRegistry = (dto) => {
  return axios(URL_REGISTRYUPDATE, {
    data: dto,
    method: 'put'
  })
}

const getVisit = () => {
  return axios.get(URL_VISIT)
}

const postVisit = (dto) => {
  return axios(URL_VISIT, {
    data: dto,
    method: 'post'
  })
}

const postFolders = (folderName, employeeUuid) => {
  return axios(URL_FOLDER + '/' + "create", {
    params: {
      folderName: folderName,
      employeeUuid: employeeUuid,
    },
    method: 'POST'
  })
}

const putVisit = (dto) => {
  return axios(URL_VISIT, {
    data: dto,
    method: 'put'
  })
}

const putRequest = (dto) => {
  return axios(URL_REQUEST, {
    data: dto,
    method: 'put'
  })
}


const deleteVisit = (id) => {
  return axios.delete(URL_VISIT + '/' + id)
}

const deleteFolder = (folderName, employeeUuid) => {
  return axios.delete(`${URL_FOLDER}/delete/${folderName}`, {
    params: {
      folderName: folderName,
      employeeUuid: employeeUuid,
    }
  });
}

export const getFoldersByEmployee = (employeeUuid) => {
  return axios.get(`${URL_FOLDER}/list-by-employee`, {
    params: { employeeUuid }
  });
};

const getFolderContents = async (parentFolder = '') => {
  try {
    const response = await axios.get(`${URL_FOLDER}/list-contents`, {
      params: { parentFolder }
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Error en la solicitud:', error.response.status, error.response.data);
    } else if (error.request) {
      console.error('Error en la red o servidor no disponible:', error.request);
    } else {
      console.error('Error al configurar la solicitud:', error.message);
    }
    throw error;
  }
};

const downloadFile = async (filePath) => {
  try {
    const encodedFileName = filePath.split('/').map(encodeURIComponent).join('/');

    const response = await axios.get(`${URL_FOLDER}/download`, {
      params: { fileName: encodedFileName },
      responseType: 'blob'
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filePath.split('/').pop());
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    if (error.response) {
      console.error('Error en la solicitud de descarga:', error.response.status, error.response.data);
    } else if (error.request) {
      console.error('Error en la red o servidor no disponible:', error.request);
    } else {
      console.error('Error al configurar la solicitud de descarga:', error.message);
    }
  }
};
export const updateRequest = (employeeUuid, data) => {
  return axios.get(`${URL_FOLDER}/list-by-employee`, {
    params: { employeeUuid }
  });
};

const getSuspension = () => {
  return axios.get(URL_SUSPENSION)
}

const getSuspensionById = (id) => {
  return axios.get(URL_SUSPENSION + '/dni' + '/' + id)
}

const deleteSuspension = (id) => {
  return axios.delete(URL_SUSPENSION + '/' + id)
}

const getExportSuspension = (id) => {
  if (!id) {
    throw new Error('ID is required to export the PDF');
  }

  const url = `${URL_SUSPENSION}/${id}/export-pdf`;

  return axios.get(url, {
    responseType: 'arraybuffer',
  })
    .then(response => {
      if (response.status === 200) {
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'downloaded_suspension.pdf';

        if (contentDisposition && contentDisposition.includes('filename')) {
          filename = contentDisposition
            .split('filename=')[1]
            .split(';')[0]
            .replace(/['"]/g, '');
        }

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        const objectUrl = window.URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = filename;
        link.click();

        window.URL.revokeObjectURL(objectUrl);
      }
    })
    .catch(error => {
      console.error('Error exporting suspension PDF:', error);
      throw error;
    });
};

const postSuspension = (dto) => {
  console.log(dto);
  return axios(URL_SUSPENSION, {
    data: JSON.stringify(dto),
    method: 'POST'
  })
}

const putSuspension = (dto) => {
  return axios(URL_SUSPENSION, {
    data: dto,
    method: 'put'
  })
}

const putImagenPerfil = async (id, formData, company) => {
  const url = `${URL_PICTURE}updateProfile/${id}?company=${company}`;

  return axios.put(url, formData, {
    headers: {
    },
  });
};

export {
  getMiPerfil,
  getRegisters,
  getRegister,
  getRegisterMonth,
  getRegisterEmployee,
  getUUID,
  getDateUser,
  getAsistencia,
  getListPersona,
  getPersonaId,
  getDocumentacion,
  getReportes,
  getStadisctic,
  getStadiscticArrival,
  getStatus,
  getBirdays,
  getDistrito,
  getCity,
  getPersona,
  getObjetivos,
  getObjetivo,
  getPuesto,
  getRequest,
  getRequestById,
  getReason,
  getExportWithDetail,
  getExportWithOutDetail,
  getExportAllFirstLastReport,
  getExportAllScheduledLogReport,
  getExportTotalTimeCardReport,
  getExportEmpSummaryReportFile,
  getPlaceObject,
  getPeriod,
  getVisit,
  getTransaction,
  getAllFirstLastReport,
  getAllScheduledLogReport,
  getAllDepartment,
  getAllTotalTimeCardReport,
  getAllEmpSummaryReport,
  getFolders,
  getFolderContents,
  getExportEmployee,
  getExportRequest,
  getSuspension,
  getSuspensionById,
  getExportSuspension,
  getImportAsistent,
  getImportEmployees,
  postFolders,
  postUser,
  postPicture,
  postEmpleado,
  postAsistencia,
  postUserLogin,
  postObjetivo,
  postPuesto,
  postRequest,
  postPeriod,
  postRegistryArray,
  postVisit,
  postSuspension,
  deleteObjetivo,
  deletePlaceObject,
  deleteRequestObject,
  deleteRegistry,
  deleteVisit,
  deleteFolder,
  deleteSuspension,
  deleteEmployee,
  putPerfil,
  putMiPerfil,
  putUsuario,
  putObjetivo,
  putPuesto,
  putRegistry,
  putVisit,
  putRequest,
  putSuspension,
  putImagenPerfil,
  downloadFile,
};
